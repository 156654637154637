import { initialState } from './initialState'

import { UIActions } from '../../actions/uiActions'
import { AnyAction } from 'redux'
import { IUIState } from './types'

const uiReducer = (state = initialState, action: AnyAction): IUIState => {
  switch (action.type) {
    case UIActions.SHOW_DELETE_MODAL:
      return {
        ...state,
        showDeleteModal: true,
      }
    case UIActions.HIDE_DELETE_MODAL:
      return {
        ...state,
        showDeleteModal: false,
      }
    case UIActions.ONLINE:
      return {
        ...state,
        offline: false,
      }
    case UIActions.OFFLINE:
      return {
        ...state,
        offline: true,
      }
    case UIActions.SET_ENVIRONMENT:
      return {
        ...state,
        env: action.data,
      }
    case UIActions.SET_USER_DEVICE:
      return {
        ...state,
        isDesktop: action.isDesktop,
      }
    case UIActions.SHOW_LOCATION_MODAL:
      return {
        ...state,
        locationModal: {
          id: action.id,
          location: action.location,
        },
      }
    case UIActions.HIDE_LOCATION_MODAL:
      return {
        ...state,
        locationModal: {
          id: undefined,
          location: undefined,
        },
      }
    case UIActions.SET_ERROR_ALERT:
      return {
        ...state,
        errorAlert: {
          isShown: action.isShown,
          text: action.text,
          title: action.title,
        },
      }
    default:
      return state
  }
}

export default uiReducer
