import { IAnsatt, IAnsatteState } from "../reducers/ansattReducer/types"
import { IStoreState } from "../reducers/types"

export const STORE_NAME = 'ansatte'

export const AnsatteActionTypes = {
  FETCH_ANSATTE: <const>`${STORE_NAME}/FETCH_ANSATTE`,
  FETCH_ALL_ANSATTE: <const>`${STORE_NAME}/FETCH_ALL_ANSATTE`,
  FETCH_ANSATTE_OK: <const>`${STORE_NAME}/FETCH_ANSATTE_OK`,
  FETCH_ANSATTE_FAIL: <const>`${STORE_NAME}/FETCH_ANSATTE_FAIL`,

  UPDATE_FORDELT_TIL: <const>`${STORE_NAME}/UPDATE_FORDELT_TIL`,
  UPDATE_FORDELT_TIL_OK: <const>`${STORE_NAME}/UPDATE_FORDELT_TIL_OK`,
  UPDATE_FORDELT_TIL_FAIL: <const>`${STORE_NAME}/UPDATE_FORDELT_TIL_FAIL`,
}

export const ansatteActions = {
  fetchAnsatte: () => ({
    type: AnsatteActionTypes.FETCH_ANSATTE,
  }),

  fetchAllAnsatte: () => ({
    type: AnsatteActionTypes.FETCH_ALL_ANSATTE,
  }),

  fetchAnsatteOk: (ansatte: IAnsatt[], kontor: IAnsatteState['kontor']) => ({
    type: AnsatteActionTypes.FETCH_ANSATTE_OK,
    ansatte,
    kontor,
  }),

  fetchAnsatteFail: (error: string) => ({
    type: AnsatteActionTypes.FETCH_ANSATTE_FAIL,
    error,
  }),

  fetchAllAnsatteOk: (ansatte: IAnsatt[], kontor: IAnsatteState['kontor']) => ({
    type: AnsatteActionTypes.FETCH_ANSATTE_OK,
    ansatte,
    kontor,
  }),

  fetchAllAnsatteFail: (error: string) => ({
    type: AnsatteActionTypes.FETCH_ANSATTE_FAIL,
    error,
  }),
}

export const ansatteSelectors = {
  getLoadingStatus: (
    state: IStoreState
  ): { loading: boolean; loaded: boolean; error: string | null } => ({
    loading: state[STORE_NAME].loading,
    loaded: state[STORE_NAME].loaded,
    error: state[STORE_NAME].error,
  }),

  getAnsatte: (state: IStoreState): IAnsatteState['ansatte'] =>
    state[STORE_NAME].ansatte,

  getKontor: (state: IStoreState): IAnsatteState['kontor'] =>
    state[STORE_NAME].kontor,
}