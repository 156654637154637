import { SisteTilsyn } from "../reducers/sisteTilsynReducer/types"

export const SisteTilsynsActions = {

    LOAD_SISTE_TILSYN: "LOAD_SISTE_TILSYN",
    LOAD_SISTE_TILSYN_OK: "LOAD_SISTE_TILSYN_OK",
    LOAD_SISTE_TILSYN_FAIL: "LOAD_SISTE_TILSYN_FAIL",
}

export const loadSisteTilsyn = (
    tilsynsobjektIds: string[]
) => ({
    type: SisteTilsynsActions.LOAD_SISTE_TILSYN,
    tilsynsobjektIds
})

export const loadSisteTilsynOk = (
    sisteTilsyn: SisteTilsyn[]
    ) => ({
        type: SisteTilsynsActions.LOAD_SISTE_TILSYN_OK,
        sisteTilsyn
})

export const loadSisteTilsynFail = (
    tilsynsobjektIds: string[]
    ) => ({
        type: SisteTilsynsActions.LOAD_SISTE_TILSYN_FAIL,
        tilsynsobjektIds
})