import initialState from "./initialState";
import { SisteTilsynAction, SisteTilsynState } from "./types";
import { SisteTilsynsActions } from "../../actions/sisteTilsynActions";

const sisteTilsynReducer = (
    state = initialState,
    action: SisteTilsynAction
  ): SisteTilsynState => {
    switch (action.type) {
      case SisteTilsynsActions.LOAD_SISTE_TILSYN:
        return {
          ...state,
          sisteTilsynLoading: [...state.sisteTilsynLoading, ...action.tilsynsobjektIds]
        }

      case SisteTilsynsActions.LOAD_SISTE_TILSYN_OK:
        return {
          ...state,
          sisteTilsynLoading: state.sisteTilsynLoading.filter(tilsynsObjektId => !action.sisteTilsyn.map(t => t.tilsynsobjektId).includes(tilsynsObjektId)),
          sisteTilsynOk: [...state.sisteTilsynOk, ...action.sisteTilsyn.map(t => t.tilsynsobjektId)],
          sisteTilsynFail: state.sisteTilsynFail.filter(tilsynsObjektId => !action.sisteTilsyn.map(t => t.tilsynsobjektId).includes(tilsynsObjektId)),
          sisteTilsyn: [...state.sisteTilsyn,  ...action.sisteTilsyn]
        }

      case SisteTilsynsActions.LOAD_SISTE_TILSYN_FAIL:
        return {
          ...state,
          sisteTilsynLoading: state.sisteTilsynLoading.filter(tilsynsObjektId => !action.tilsynsobjektIds.includes(tilsynsObjektId)),
          sisteTilsynFail: [...state.sisteTilsynFail, ...action.tilsynsobjektIds],
        }
    }
    return state
  };

  export default sisteTilsynReducer