import React from 'react'
import { TextLink, ShadowBox } from '@mattilsynet/mt-ui'
import './style.css'

const AccessDeniedRoute = () => {
  return (
    <div id='access-denied' className='page-content'>
      <main>
        <ShadowBox>
          <div>
            <div>Du har ikke tilgang til Mattilsynets Datadrevet Risikobasering.</div>
            <div className='support-info'>
              Kontakt brukerstøtte og{' '}
              <TextLink href='/login/callback'>prøv på nytt</TextLink>.
            </div>
          </div>

          <div className='support-info'>
            <h2>Brukerstøtte</h2>
            <div className='support-link'>
              Opprett{' '}
              <TextLink href='https://makuba.mattilsynet.no/' linkOutside>
                brukerstøttesak
              </TextLink>
            </div>
            <div>
              Du kan også kontakte IKT-brukerstøtte på telefon eller e-post:
            </div>
            <div>
              Telefon: <TextLink href='tel:22 77 88 99'>22 77 88 99</TextLink>
            </div>
            <div>
              E-post:{' '}
              <TextLink href='mailto:99@mattilsynet.no'>
                99@mattilsynet.no
              </TextLink>
            </div>
          </div>
        </ShadowBox>
      </main>
    </div>
  )
}

export default AccessDeniedRoute
