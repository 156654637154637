import { TilsynsobjektRowActions } from "../../actions/tilsynsobjektRowActions";
import initialState from "./initialState";
import { TilsynsobjektRowAction, TilsynsobjektRowState } from "./types";

const tilsynsobjektRowReducer = (
    state = initialState,
    action: TilsynsobjektRowAction
  ): TilsynsobjektRowState => {
    switch (action.type) {
      case TilsynsobjektRowActions.OPEN_ROW:
        return {
          ...state,
          openRows: [...state.openRows, action.tilsynsObjektId]
        }

      case TilsynsobjektRowActions.CLOSE_ROW:
        return {
          ...state,
          openRows: state.openRows.filter(tilsynsObjektId => tilsynsObjektId != action.tilsynsObjektId),
        }
    }
    return state
  };

  export default tilsynsobjektRowReducer