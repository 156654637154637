import { IAnsatteState } from "./types";

const initialState: IAnsatteState = {
    loading: false,
    loaded: false,
    error: null,
    ansatte: [],
    kontor: undefined,
  }

  export default initialState;