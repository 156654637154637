export const UserActions = {
  SET_USER: 'User: Set logged in user',
  GET_USER: 'User: Get logged in user',
  UPDATE_TOKEN: 'user/UPDATE_TOKEN',
  USER_EXPIRED: 'user/USER_EXPIRED',
  USER_SIGNED_OUT: 'user/USER_SIGNED_OUT',
  IMAGES_LOADED: 'User: Set images as loaded',
  SET_LAST_LOCATION: 'User: set last location',
  FETCH_USER_AVDELING_FAIL: 'User: FETCH_USER_AVDELING_FAIL',
  FETCH_USER_AVDELING_OK: 'User: FETCH_USER_AVDELING_OK',
  FETCH_USER_ORGENHET_FAIL: 'User: FETCH_USER_ORGENHET_FAIL',
  FETCH_USER_ORGENHET_OK: 'User: FETCH_USER_ORGENHET_OK',
}

export const setUser = (
  username: string,
  name: string,
  title: string,
  token?: string //TODO: Set as optional as there some places where it is not used
) => ({
  type: UserActions.SET_USER,
  username,
  name,
  title,
  token,
})

export const imagesLoaded = () => ({
  type: UserActions.IMAGES_LOADED,
})

export const getUser = () => ({
  type: UserActions.GET_USER,
})

export const setLastLocation = (lastLocation) => ({
  type: UserActions.SET_LAST_LOCATION,
  lastLocation,
})

export const fetchUserAvdelingFail = (err) => ({
  type: UserActions.FETCH_USER_AVDELING_FAIL,
  err,
})

export const fetchUserAvdelingOk = (data) => ({
  type: UserActions.FETCH_USER_AVDELING_OK,
  data,
})

export const fetchUserOrgenhetFail = (err) => ({
  type: UserActions.FETCH_USER_ORGENHET_FAIL,
  err,
})

export const fetchUserOrgenhetOk = (data) => ({
  type: UserActions.FETCH_USER_ORGENHET_OK,
  data,
})

export const updateToken = (accessToken) => ({
  type: UserActions.UPDATE_TOKEN,
  data: accessToken,
})

export const userExpired = () => ({
  type: UserActions.USER_EXPIRED,
})

export const userSignedOut = () => ({
  type: UserActions.USER_SIGNED_OUT,
})
