import React from "react";
import dayjs from "dayjs";
import { RadioButtonItem, TextLink } from "@mattilsynet/mt-ui";

enum DateButtonLabels {
  ETT_AAR = "1år",
  TO_AAR = "2år",
  TRE_AAR = "3år",
  VELG_DATO = "Velg dato",
}

interface IFiltreringDateButtons {
  filterSisteTilsynDate: string | null;
  showDatePicker: boolean;
  setFilterSistetilsynDate: (date: string) => void;
  setShowDatePicker: (showDatePicker: boolean) => void;
}

export const FiltreringDateButtons = ({
  filterSisteTilsynDate,
  showDatePicker,
  setFilterSistetilsynDate,
  setShowDatePicker,
}: IFiltreringDateButtons) => {
  const oneYearAgo = dayjs().subtract(1, "year").format("YYYY-MM-DD");
  const twoYearsAgo = dayjs().subtract(2, "year").format("YYYY-MM-DD");
  const threeYearsAgo = dayjs().subtract(3, "year").format("YYYY-MM-DD");

  let isSelected = "";
  switch (filterSisteTilsynDate) {
    case oneYearAgo:
      isSelected = DateButtonLabels.ETT_AAR;
      break;
    case twoYearsAgo:
      isSelected = DateButtonLabels.TO_AAR;
      break;
    case threeYearsAgo:
      isSelected = DateButtonLabels.TRE_AAR;
      break;
    case null:
      isSelected = DateButtonLabels.VELG_DATO;
      break;
  }

  const onClickButton = (label: string) => {
    setShowDatePicker(false);

    const mapper = {
      [DateButtonLabels.ETT_AAR]: oneYearAgo,
      [DateButtonLabels.TO_AAR]: twoYearsAgo,
      [DateButtonLabels.TRE_AAR]: threeYearsAgo,
      [DateButtonLabels.VELG_DATO]: null,
    };

    setFilterSistetilsynDate(mapper[label]);

    if (label === DateButtonLabels.VELG_DATO) {
      setShowDatePicker(true);
    }
  };

  const onRemoveChosenDate = () => {
    setShowDatePicker(false);
    setFilterSistetilsynDate(undefined);
  };

  return (
    <>
      {Object.values(DateButtonLabels).map((value: string) => {
        const isButtonSelected =
          isSelected === value ||
          (value === DateButtonLabels.VELG_DATO && showDatePicker);

        return (
          <RadioButtonItem
            key={value}
            onClick={() => {
              onClickButton(value);
            }}
            id={value}
            selected={isButtonSelected}
          >
            {value}
          </RadioButtonItem>
        );
      })}
      <TextLink small onClick={onRemoveChosenDate}>
        Fjern valgt dato
      </TextLink>
    </>
  );
};
