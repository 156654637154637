import { RiskScoringActions } from "../../actions/riskscoringActions";
import initialState from "./initialState";
import { RiskScoringAction, RiskScoringState } from "./types";

const svineholdReducer = (
    state = initialState,
    action: RiskScoringAction
  ): RiskScoringState => {
    switch (action.type) {
      case RiskScoringActions.LOAD_RISKSCORINGS:
        return {
          ...state,
          tilsynsobjekter: [
            ...state.tilsynsobjekter,
            ...action.currentRiskScoringData,
          ],
          loading: false,
          initialized: true,
          pageMetaData: action.pageMetaData
        }

      case RiskScoringActions.LOADING_RISKSCORINGS:
        return {
          ...state,
          loading: true
        }

      case RiskScoringActions.ADD_RISKSCORINGS:
        return {
          ...state,
          tilsynsobjekter: [...state.tilsynsobjekter ?? [],
            ...action.currentRiskScoringData ?? []],
          loading: true
        }

      case RiskScoringActions.SET_PLANNED_TILSYN:
        return {
          ...state,
          plannedTilsyn: action.plannedTilsyn
        }

        case RiskScoringActions.ADD_PLANNED_TILSYN:
          return {
            ...state,
            plannedTilsyn: [...state.plannedTilsyn ?? [],
              ...action.plannedTilsyn ?? []]
          }

        case RiskScoringActions.CANCEL_PLANNED_TILSYN:
          return {
            ...state,
            plannedTilsyn: [...state.plannedTilsyn?.filter(pt => 
              pt.tilsynsobjekt != (action.plannedTilsyn||[])[0].tilsynsobjekt ||
              pt.username != (action.plannedTilsyn||[])[0].username) ?? []]
          }

      case RiskScoringActions.WS_CONNECTED:
        return {
          ...state,
          wsClient: action.wsClient
        }

      case RiskScoringActions.WS_DISCONNECTED:
        return {
          ...state,
          wsClient: action.wsClient
        }
    }
    return state
  };

  export default svineholdReducer