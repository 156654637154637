export interface IProduksjonsformItem {
  tilsynsobjektId: string;
  produksjonsformer: IProduksjonsform[];
}

export enum ProduksjonsformerActionTypes {
  GET_PRODUKSJONFORMER = "GET_PRODUKSJONFORMER",
  GET_PRODUKSJONFORMER_OK = "GET_PRODUKSJONFORMER_OK",
  GET_PRODUKSJONFORMER_FAIL = "GET_PRODUKSJONFORMER_FAIL",
}

export interface ITilsynsobjektProduksjonsformerState {
  tilsynsobjektProduksjonsformerLoading: boolean;
  tilsynsobjektProduksjonsformerFail: boolean;
  tilsynsobjektProduksjonsformer: IProduksjonsformItem[];
}

export interface ITilsynsobjektProfuksjonsformerAction {
  type: ProduksjonsformerActionTypes;
  tilsynsobjektId: string;
  produksjonsformer?: IProduksjonsform[];
}

export interface IProduksjonsform {
  description: string;
  fraDato: string;
  tilDato: string;
  type: string;
}
