import { CalendarIcon, Row, RowItem, Text } from "@mattilsynet/mt-ui";
import React, { forwardRef } from "react";
import dayjs from "dayjs";

export const CalendarInput = forwardRef(
  //  eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ onClick, dato }: any) => (
    <Row>
      <Row className="calendar-input" onClick={onClick} spacing={2}>
        <RowItem padding={1} flex={5}>
          <Text>{dato ? dayjs(dato).format("D. MMM YYYY") : "Velg dato"}</Text>
        </RowItem>
        <RowItem padding={1} flex={1}>
          <CalendarIcon color="grey" size="medium" />
        </RowItem>
      </Row>
    </Row>
  )
);
