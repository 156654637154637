import initialState from "./initialState";
import {
  ITidligereTilsynAction,
  TidligereTilsynActionTypes,
  ITidligereTilsynState,
  ITidligereTilsynskvitteringerResponse,
} from "./types";

const parseTidligereTilsyn = (
  data: ITidligereTilsynskvitteringerResponse[]
) => {
  return data.map(({ tilsynsobjektId, tilsynskvitteringer }) => ({
    tilsynsobjektId,
    tilsynskvitteringer: tilsynskvitteringer.map(
      ({ ansattNavn, status, tilsynsdato, tilsynskvitteringId }) => ({
        ansattNavn,
        status,
        tilsynsdato,
        tilsynskvitteringId,
      })
    ),
  }));
};

export const tidligereTilsynActions = {
  getTidligereTilsyn: (): ITidligereTilsynAction => ({
    type: TidligereTilsynActionTypes.GET_TIDLIGERE_TILSYN,
  }),
  tidligereTilsynOk: (
    tidligereTilsyn: ITidligereTilsynskvitteringerResponse[],
    tilsynsobjektIds: string[]
  ): ITidligereTilsynAction => ({
    type: TidligereTilsynActionTypes.TIDLIGERE_TILSYN_OK,
    tidligereTilsyn,
    tilsynsobjektIds,
  }),
  tidligereTilsynFail: (): ITidligereTilsynAction => ({
    type: TidligereTilsynActionTypes.TIDLIGERE_TILSYN_FAIL,
  }),
};

const tidligereTilsynReducer = (
  state = initialState,
  action: ITidligereTilsynAction
): ITidligereTilsynState => {
  switch (action.type) {
    case TidligereTilsynActionTypes.GET_TIDLIGERE_TILSYN:
      return {
        ...state,
        tidligereTilsynLoading: true,
      };
    case TidligereTilsynActionTypes.TIDLIGERE_TILSYN_OK:
      return {
        ...state,
        tidligereTilsynLoading: false,
        tidligereTilsynFail: false,
        tidligereTilsyn: [
          ...state.tidligereTilsyn,
          ...parseTidligereTilsyn(action.tidligereTilsyn),
        ],
      };
    case TidligereTilsynActionTypes.TIDLIGERE_TILSYN_FAIL:
      return {
        ...state,
        tidligereTilsynLoading: false,
        tidligereTilsynFail: true,
      };
    default:
      return state;
  }
};

export default tidligereTilsynReducer;
