import {
  Button,
  Column,
  ErrorBox,
  LaunchIcon,
  LoadingDots,
  Table,
  TableCell,
  TableRow,
  Text,
  TextLink,
} from "@mattilsynet/mt-ui";
import React from "react";
import { VirksomhetAdresseInfo } from "../virksomhet-address-info";
import classNames from "classnames";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { IStoreState } from "../../reducers/types";
import { ITidligereTilsyn } from "../../reducers/tidligereTilsynReducer/types";
import { useDispatch } from "react-redux";
import {
  planTilsynAction,
  planTilsynFail,
  planTilsynOk,
} from "../../actions/tilsynActions";
import { planTilsyn } from "../../api/tilsyn";
import { tilsynPlannedError, tilsynPlannedSuccess } from "../../common/toast";
import { toast } from "@mattilsynet/mt-common";
import {
  useProduksjonsformer,
  useTidligereTilsynskvitteringer,
  useTilsynsobjektinfoUrl,
} from "./hooks";
import { Produksjonsformer } from "../produksjonsformer";
import "./style.css";

interface ITilsynsobjektRowShowMore {
  backgroundColor: "bg-gray" | "bg-white";
  avdeling: string;
  isSmallScreen: boolean;
  tilsynsobjektId: string;
  onFordelTilKollega: (tilsynsobjektId: string) => void;
}

export const TilsynsobjektRowShowMore = ({
  backgroundColor,
  avdeling,
  isSmallScreen,
  tilsynsobjektId,
  onFordelTilKollega,
}: ITilsynsobjektRowShowMore) => {
  const { tilsynLoading, tilsynPlanned } = useSelector(
    (state: IStoreState) => state.tilsyn
  );

  const { accessToken } = useSelector((state: IStoreState) => state.user);

  const { username } = useSelector(
    (state: IStoreState) => state.user.userOrgenhet
  );

  const { addresses, addressLoading } = useSelector(
    (state: IStoreState) => state.address
  );

  const tilsynsobjektinfoUrl = useTilsynsobjektinfoUrl(tilsynsobjektId);

  const dispatch = useDispatch();

  const {
    produksjonsformer,
    tilsynsobjektProduksjonsformerFail: produksjonsformerFail,
    tilsynsobjektProduksjonsformerLoading: produksjonsformerLoading,
  } = useProduksjonsformer(tilsynsobjektId, accessToken);

  const {
    tidligereTilsynskvitteringer,
    tidligereTilsynLoading,
    tidligereTilsynFail,
  } = useTidligereTilsynskvitteringer(tilsynsobjektId, accessToken);

  const isAddressLoading = () => {
    return addressLoading.some((t) => t == tilsynsobjektId);
  };

  const getAddress = () => {
    return addresses.some((t) => t.tilsynsobjektId == tilsynsobjektId)
      ? addresses.filter((t) => t.tilsynsobjektId == tilsynsobjektId)[0]
      : null;
  };

  const goToTilsynsobjektinfo = () => {
    window.open(tilsynsobjektinfoUrl, "_blank");
  };

  const renderTidligereTilsyn = () => {
    const tableHeader = [
      { display: "Tilsynsdato" },
      { display: "Status" },
      { display: "Inspektør" },
    ];

    if (tidligereTilsynLoading) {
      return <LoadingDots />;
    }

    if (tidligereTilsynFail) {
      return (
        <ErrorBox errorText="Det skjedde en feil ved henting av tidligere tilsynskvitteringer." />
      );
    }

    if (tidligereTilsynskvitteringer?.length === 0) {
      return <Text>Det finnes ingen tidligere tilsynskvitteringer</Text>;
    }

    return (
      <Table
        className="tidligere-tilsynskvitteringer-table"
        header={tableHeader}
      >
        {tidligereTilsynskvitteringer ? (
          tidligereTilsynskvitteringer?.map(
            ({
              tilsynsdato,
              status,
              ansattNavn,
              tilsynskvitteringId,
            }: ITidligereTilsyn) => (
              <TableRow
                className={classNames(backgroundColor)}
                key={tilsynskvitteringId}
              >
                <TableCell>
                  {dayjs(tilsynsdato).format("D. MMM YYYY")}
                </TableCell>
                <TableCell>{status}</TableCell>
                <TableCell>{ansattNavn}</TableCell>
              </TableRow>
            )
          )
        ) : (
          <></>
        )}
      </Table>
    );
  };

  const renderFordelSection = () => {
    return (
      <Column
        spacing={1}
        padding={isSmallScreen ? [3, 3] : [3, 3, 3, 0]}
        className="oppfoelging-box"
      >
        <Column spacing={1}>
          {tilsynLoading.filter((t) => t == tilsynsobjektId).length > 0 ? (
            <div className="confirmed-tilsyn">
              <LoadingDots />
            </div>
          ) : tilsynPlanned.filter((t) => t == tilsynsobjektId).length > 0 ? (
            <div className="confirmed-tilsyn">Tilsynskvittering opprettet</div>
          ) : (
            <>
              <Button
                secondary
                onClick={() => {
                  dispatch(planTilsynAction(tilsynsobjektId));
                  planTilsyn(
                    tilsynsobjektId,
                    username,
                    accessToken,
                    () => {
                      dispatch(planTilsynOk(tilsynsobjektId));
                      dispatch(toast.actions.showToast(tilsynPlannedSuccess()));
                    },
                    () => {
                      dispatch(planTilsynFail(tilsynsobjektId));
                      dispatch(toast.actions.showToast(tilsynPlannedError()));
                    }
                  );
                }}
              >
                Opprett tilsynskvittering til deg
              </Button>
              <Button
                secondary
                onClick={() => onFordelTilKollega(tilsynsobjektId)}
              >
                Opprett tilsynskvittering til kollega
              </Button>
              <Button
                icon={<LaunchIcon />}
                iconAfter
                secondary
                onClick={goToTilsynsobjektinfo}
              >
                Gå til tilsynsobjektinfo
              </Button>
            </>
          )}
        </Column>
      </Column>
    );
  };

  return (
    <TableRow
      className={classNames(backgroundColor, "show-more-row")}
      header={[" ", " ", " "]}
    >
      <TableCell colSpan={3} className="show-more-content">
        <Column spacing={4} padding={[3, 3, 0, 3]} className="addresse-box">
          <Column spacing={2}>
            {isAddressLoading() ? (
              <LoadingDots />
            ) : (
              <VirksomhetAdresseInfo
                tittel="Beliggenhetsadresse"
                adresse={getAddress()}
              />
            )}
          </Column>
          <Column spacing={2}>
            <Text as="h3" weight="bold" size="heading3">
              Tilhørighet
            </Text>
            <Text as="p">{avdeling}</Text>
          </Column>
          <Column spacing={2}>
            <Produksjonsformer
              produksjonsformer={produksjonsformer?.produksjonsformer}
              isLoading={produksjonsformerLoading}
              isError={produksjonsformerFail}
            />
          </Column>
        </Column>
      </TableCell>
      <TableCell
        colSpan={1}
        className={classNames({ "show-more-content": !isSmallScreen })}
      >
        <Column
          spacing={1}
          padding={isSmallScreen ? [3, 3, 0, 3] : [3, 3, 0, 0]}
        >
          <Text as="h4" weight="bold">
            Tidligere tilsynskvitteringer
          </Text>
          <Column className="funn-box">{renderTidligereTilsyn()}</Column>
        </Column>
      </TableCell>
      <TableCell
        colSpan={1}
        className={classNames({ "show-more-content": !isSmallScreen })}
      >
        {renderFordelSection()}
      </TableCell>
    </TableRow>
  );
};
