export const LOAD_RISKSCORINGS = "LOAD_RISKSCORINGS";
export const LOADING_RISKSCORINGS = "LOADING_RISKSCORINGS";
export const ADD_RISKSCORINGS = "ADD_RISKSCORINGS";

export const ADD_PLANNED_TILSYN = "ADD_PLANNED_TILSYN";
export const SET_PLANNED_TILSYN = "SET_PLANNED_TILSYN";
export const CANCEL_PLANNED_TILSYN = "CANCEL_PLANNED_TILSYN";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const WS_CONNECTED = "WS_CONNECTED";
export const WS_DISCONNECTED = "WS_DISCONNECTED";