import { of, combineLatest } from 'rxjs'
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
  mapTo,
  take,
} from 'rxjs/operators'
import {
  UserActions,
  fetchUserAvdelingFail,
  fetchUserAvdelingOk,
  fetchUserOrgenhetFail,
  fetchUserOrgenhetOk,
} from '../../actions/userActions'
import { Epic, ofType, combineEpics } from 'redux-observable'

// Types
import { IStoreState } from '../../reducers/types'
import { AnyAction } from 'redux'

// Injecables
import userApi from '../../api/user'

import { notifications } from '@mattilsynet/mt-common'
import { UIActions } from '../../actions/uiActions'

/**
 * Listens for redux action [[UserActions.FETCH_USER_ORGENHET_OK]]
 * fetches users *avdeling* based on `id` stored in
 *
 * @param api object containing [[getUserAvdeling]] function, returning `Observable<Ajax>`
 * @param `action$` Steam of actions from `redux-observable` (injected for testing purposes)
 * @category epic
 * @return OK: [[UserActions.FETCH_USER_AVDELING_OK]] with info about users *avdeling*
 * @return FAIL: [[UserActions.FETCH_USER_AVDELING_FAIL]]
 *
 */
export const getUserAvdelingEpic =
  (api = userApi): Epic<AnyAction, AnyAction> =>
  (action$) =>
    action$.pipe(
      ofType(UserActions.FETCH_USER_ORGENHET_OK),
      switchMap(({ data }) =>
        of(data).pipe(
          mergeMap(() => api.getUserAvdeling(data.id)),
          map((resp: any) => fetchUserAvdelingOk(resp.response)),
          catchError((err) => of(fetchUserAvdelingFail(err.message)))
        )
      )
    )

/**
 * Listens for redux action [[UserActions.SET_USER]]
 * fetch information about logged in user
 *
 * @category epic
 * @return OK: [[UserActions.FETCH_USER_ORGENHET_OK]] with information about logged in user
 * @return FAIL: [[UserActions.FETCH_USER_ORGENHET_FAIL]]
 */
export const getUserOrgenhetEpic =
  (api = userApi): Epic<AnyAction, AnyAction, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      ofType(UserActions.SET_USER),
      withLatestFrom(state$),
      switchMap(([, state]) =>
        of(state).pipe(
          mergeMap(() => api.getUserOrgenhet()),
          map((resp: any) => fetchUserOrgenhetOk(resp.response)),
          catchError((err) => of(fetchUserOrgenhetFail(err.message)))
        )
      )
    )

export enum APPLIKASJONER {
  MAKKS = 'MAKKS',
  TILSYNSKVITTERING = 'Tilsynskvittering',
  FOTO = 'Fotoløsning',
}

/**
 * Start polling for notifications in mt-common/notification.
 *
 * Listen for initial user login and every [[UIActions.ONLINE]] event to
 * start fetching notifications.
 */
export const startPollingNotificationsEpic =
  (): Epic<AnyAction, AnyAction, IStoreState> => (action$) =>
    combineLatest(
      action$.pipe(ofType(UserActions.SET_USER), take(1)),
      action$.pipe(ofType(UIActions.ONLINE))
    ).pipe(
      mapTo(notifications.actions.startPolling(15000, [APPLIKASJONER.FOTO]))
    )

/**
 * Stop polling for notifications when application in mt-common/notification goes offline
 */
export const pausePollingNotifications =
  (): Epic<AnyAction, AnyAction, IStoreState> => (action$) =>
    action$.pipe(
      ofType(
        UIActions.OFFLINE,
        UserActions.USER_SIGNED_OUT,
        UserActions.USER_EXPIRED
      ),
      mapTo(notifications.actions.stopPolling())
    )

export default combineEpics(
  getUserAvdelingEpic(userApi),
  getUserOrgenhetEpic(userApi),
  startPollingNotificationsEpic(),
  pausePollingNotifications()
)
