import React from 'react'
import { Column, Header, Text } from '@mattilsynet/mt-ui'
import { IVirksomhetAdresse } from '../../model/type'

interface IVirksomhetAdresseInfo {
  adresse?: IVirksomhetAdresse
  tittel: string
}

export const VirksomhetAdresseInfo = ({
  adresse,
  tittel,
}: IVirksomhetAdresseInfo) => {
  return (
    <Column justify={'flex-start'} align={'flex-start'} spacing={1}>
      <Header as='h3' size='normal'>
        {tittel}
      </Header>
      {adresse != null ? 
      <>
        <Column>
            {adresse.adresse.length > 0 &&
            adresse.adresse.map((adresse) => (
                <Text key={adresse} textAlign='left'>
                {adresse}
                </Text>
            ))}
        </Column>
        <Column>
            <Text>{`${adresse?.postnummer || ''} ${adresse?.poststed || ''}`}</Text>
        </Column>
      </> : 
      "Ingen adresse registrert" }
    </Column>
  )
}
