import { AnsatteActionTypes } from "../../actions/ansatteActions";
import initialState from "./initialState";
import { IAnsatteActions, IAnsatteState } from "./types";


const ansattReducer = (
    state = initialState,
    action: IAnsatteActions
): IAnsatteState => {
    switch (action.type) {
        case AnsatteActionTypes.FETCH_ANSATTE || AnsatteActionTypes.FETCH_ALL_ANSATTE:
            return {
              ...state,
              loading: true,
              error: null,
            }

        case AnsatteActionTypes.FETCH_ANSATTE_OK:
            return {
                ...state,
                loading: false,
                loaded: true,
                ansatte: action.ansatte,
                kontor: action.kontor
            }

        case AnsatteActionTypes.FETCH_ANSATTE_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
                loaded: true
            }
    }
    
    return state;
};

export default ansattReducer