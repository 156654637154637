export const UIActions = {
  HIDE_DELETE_MODAL: 'UI: Hide delete modal',
  OFFLINE: 'UI: Set application in offline mode',
  ONLINE: 'UI: Set application in online mode',
  SET_ENVIRONMENT: 'UI: Set application environment',
  SHOW_DELETE_MODAL: 'UI: Show delete modal',
  SW_CACHE_UPDATED: 'UI: Service worker cache updated',
  SW_CACHE_UPDATE_ERROR: 'UI/SW_CACHE_UPDATE_ERROR',
  SET_USER_DEVICE: 'UI: set user device',
  SET_USER_TOGGLES: 'UI: set user toggles',
  SHOW_LOCATION_MODAL: 'UI: show location modal',
  HIDE_LOCATION_MODAL: 'UI: hide location modal',
  SET_ERROR_ALERT: 'UI/SET_ERROR_ALERT',
}

export const showDeleteModal = () => ({
  type: UIActions.SHOW_DELETE_MODAL,
})

export const hideDeleteModal = () => ({
  type: UIActions.HIDE_DELETE_MODAL,
})

export const isOnline = () => ({
  type: UIActions.ONLINE,
})

export const isOffline = () => ({
  type: UIActions.OFFLINE,
})

export const serviceWorkerCacheUpdated = () => ({
  type: UIActions.SW_CACHE_UPDATED,
})

export const serviceWorkerCacheUpdateError = (err) => ({
  type: UIActions.SW_CACHE_UPDATE_ERROR,
  err,
})

export const setEnvironment = (env) => ({
  type: UIActions.SET_ENVIRONMENT,
  data: env,
})

export const setUserDevice = (isDesktop) => ({
  type: UIActions.SET_USER_DEVICE,
  isDesktop,
})

export const showLocationModal = (id, location) => ({
  type: UIActions.SHOW_LOCATION_MODAL,
  id,
  location,
})

export const hideLocationModal = () => ({
  type: UIActions.HIDE_LOCATION_MODAL,
})

export const setErrorAlert = (
  isShown: boolean,
  title?: string,
  text?: string
) => ({
  type: UIActions.SET_ERROR_ALERT,
  isShown,
  title,
  text,
})
