import { of } from 'rxjs'

import {
  withAccessTokenHeader,
  withJsonHeader,
  toAjaxRequest,
  handleUnauthorized,
} from './common'

const getUserOrgenhet = () =>
  of({
    url: `${process.env.REACT_APP_DRISK_BASE_URL}/api/v1/me/orgenhet`,
  }).pipe(
    withAccessTokenHeader() as any,
    withJsonHeader(),
    toAjaxRequest(),
    handleUnauthorized()
  )

const getUserAvdeling = (id) =>
  of({
    url: `${process.env.REACT_APP_DRISK_BASE_URL}/api/orgenheter/parenttype/Avdeling/id/${id}`,
  }).pipe(
    withAccessTokenHeader() as any,
    withJsonHeader(),
    toAjaxRequest(),
    handleUnauthorized()
  )

export default {
  getUserAvdeling,
  getUserOrgenhet,
}
