import React, { useState } from "react";
import { Accordion, ErrorBox, LoadingDots, Text } from "@mattilsynet/mt-ui";
import { IProduksjonsform } from "../../reducers/produksjonsformerReducer/types";
import dayjs from "dayjs";
import "./style.css";

interface IProduksjonsformerProps {
  produksjonsformer: IProduksjonsform[];
  isLoading: boolean;
  isError: boolean;
}

export const Produksjonsformer = ({
  produksjonsformer,
  isLoading,
  isError,
}: IProduksjonsformerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen((current) => !current);

  const renderDates = (fraDato: string, tilDato: string) => {
    const from = fraDato ? dayjs(fraDato).format("D.MMM YYYY") : "";
    const to = tilDato ? dayjs(tilDato).format("D.MMM YYYY") : "";

    return `${from} - ${to}`;
  };

  if (isLoading) {
    return <LoadingDots />;
  }

  if (isError) {
    return <ErrorBox errorText="Klarte ikke å hente produksjonsformer" />;
  }

  return (
    <div className="produksjonsformer-accordion">
      <Accordion
        onClick={toggleOpen}
        open={isOpen}
        title="Produksjonsformer"
        small
        paddingChildren={[0, 0, 0, 0]}
      >
        {produksjonsformer?.map(
          ({ description, fraDato, tilDato, type }: IProduksjonsform) => (
            <div key={`${type}${fraDato}${tilDato}`}>
              <Text size="heading3">{description}</Text>
              <Text>{renderDates(fraDato, tilDato)}</Text>
            </div>
          )
        )}
      </Accordion>
    </div>
  );
};
