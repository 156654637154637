import initialState from "./initialState";
import {
  IProduksjonsform,
  ITilsynsobjektProfuksjonsformerAction,
  ProduksjonsformerActionTypes,
} from "./types";

export const produksjonsformerActions = {
  getProduksjonsformer: () => ({
    type: ProduksjonsformerActionTypes.GET_PRODUKSJONFORMER,
  }),
  getProduksjonsformerOk: (
    tilsynsobjektId: string,
    produksjonsformer: IProduksjonsform[]
  ) => ({
    type: ProduksjonsformerActionTypes.GET_PRODUKSJONFORMER_OK,
    tilsynsobjektId,
    produksjonsformer,
  }),
  getProduksjonsformerFail: () => ({
    type: ProduksjonsformerActionTypes.GET_PRODUKSJONFORMER_FAIL,
  }),
};

export const produksjonsformerReducer = (
  state = initialState,
  action: ITilsynsobjektProfuksjonsformerAction
) => {
  switch (action.type) {
    case ProduksjonsformerActionTypes.GET_PRODUKSJONFORMER:
      return {
        ...state,
        tilsynsobjektProduksjonsformerLoading: true,
      };
    case ProduksjonsformerActionTypes.GET_PRODUKSJONFORMER_OK:
      return {
        ...state,
        tilsynsobjektProduksjonsformerLoading: false,
        tilsynsobjektProduksjonsformer: [
          ...state.tilsynsobjektProduksjonsformer,
          {
            tilsynsobjektId: action.tilsynsobjektId,
            produksjonsformer: action.produksjonsformer,
          },
        ],
      };
    case ProduksjonsformerActionTypes.GET_PRODUKSJONFORMER_FAIL:
      return {
        ...state,
        tilsynsobjektProduksjonsformerLoading: false,
        tilsynsobjektProduksjonsformerFail: true,
      };
    default:
      return state;
  }
};
