import { IProduksjonsform } from "../reducers/produksjonsformerReducer/types";

export const fetchProduksjonsformer = (
  tilsynsobjektId: string,
  accessToken: string,
  onSuccess: (data: IProduksjonsform[]) => void,
  onError: () => void
) => {
  fetch(
    `${process.env.REACT_APP_DRISK_BASE_URL}/api/tilsynsobjekt-api/tilsynsobjekter/id/${tilsynsobjektId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((result) => onSuccess(result.godkjenninger))
    .catch(() => onError());
};
