import { updateInstalled } from './App' // eslint-disable-line no-unused-vars

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').then(
      (registration) => {
        registration.addEventListener('updatefound', function () {
          updateInstalled(registration)
        })
      },
      (error) => {
        // eslint-disable-next-line
        console.warn('Error from SW', error)
      }
    )
  })
} else {
  // eslint-disable-next-line
  console.warn('Service worker not supported')
}
