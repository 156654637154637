import { TilsynActions } from "../../actions/tilsynActions";
import initialState from "./initialState";
import { TilsynAction, TilsynState } from "./types";

const tilsynReducer = (
    state = initialState,
    action: TilsynAction
  ): TilsynState => {
    switch (action.type) {
      case TilsynActions.PLAN_TILSYN_LOADING:
        return {
          ...state,
          tilsynLoading: [...state.tilsynLoading, action.tilsynsObjektId]
        }

      case TilsynActions.PLAN_TILSYN_OK:
        return {
          ...state,
          tilsynLoading: state.tilsynLoading.filter(tilsynsObjektId => tilsynsObjektId != action.tilsynsObjektId),
          tilsynPlanned: [...state.tilsynPlanned, action.tilsynsObjektId],
          fail: false
        }

      case TilsynActions.PLAN_TILSYN_FAIL:
        return {
          ...state,
          tilsynLoading: state.tilsynLoading.filter(tilsynsObjektId => tilsynsObjektId != action.tilsynsObjektId),
          fail: true
        }
    }
    return state
  };

  export default tilsynReducer