import "react-pro-sidebar/dist/css/styles.css";
import { Redirect, Route, Router } from "react-router-dom";
import "./App.css";
import React from "react";
import { Provider } from "react-redux";

import { v1 as uuidV1 } from "uuid";

import AccessDeniedRoute from "./routes/access-denied";
import About from "./routes/about";
import { Login, Logout, LogoutCallback } from "./containers/login";

import { AppHeader, colors, ThemeProvider } from "@mattilsynet/mt-ui";
import TopMenuElements from "./containers/top-menu-elements";

// import dayjs
import dayjs from "dayjs";
import "dayjs/locale/nb";

// Import router history
import history from "./history";
import { useTypedSelector } from "./common/hooks";
import reportWebVitals from "./reportWebVitals";
import store from "./reducers/store";
import { notifications, Toast } from "@mattilsynet/mt-common";
import ReactDOM from "react-dom";

// RxJs
import { config$ } from "./config";
import { map } from "rxjs/operators";

// Actions
import { isOffline, isOnline, setEnvironment } from "./actions/uiActions";
import { RiskScoringView } from "./pages/home/view";

// Set correct date locale
dayjs.locale("nb");

window.addEventListener("offline", () => store.dispatch(isOffline()));
window.addEventListener("online", () => store.dispatch(isOnline()));
if (window.navigator.onLine) {
  store.dispatch(isOnline());
} else {
  store.dispatch(isOffline());
}

const PrivateRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const updateNotificationUUID = uuidV1();

export const updateInstalled = () => {
  store.dispatch({ type: "UPDATE_INSTALLED" });
  store.dispatch(
    notifications.actions.add([
      {
        id: updateNotificationUUID,
        title: "Applikasjon oppdatert",
        body: "Oppdatering vil bli tatt i bruk neste gang du åpner applikasjonen",
        created: new Date().toString(),
        status: "info",
        local: true,
        link: {
          onClick: () => window.location.reload(),
          text: "Last inn applikasjonen på nytt",
        },
      },
    ])
  );
};

const themePicker = (env: string) => {
  switch (env) {
    case "prod":
    case "dev":
    case "utv":
      return colors.defaultTheme;
    default:
      return colors.testTheme;
  }
};

const AppView = ({ user, env }: { user: string; env: string }) => {
  const { offline } = useTypedSelector((state) => state.ui);
  const theme = themePicker(env);

  return (
    <div className="App">
      <ThemeProvider value={theme}>
        <Toast.FixedToastArea id="common-toastarea" />
        <Router history={history}>
          <div>
            <AppHeader
              appName="Datadrevet Risikobasering"
              env={env}
              isOffline={offline}
              right={<TopMenuElements />}
            />

            <PrivateRoute
              user={user}
              exact
              path="/"
              component={RiskScoringView}
            />
            <PrivateRoute
              user={user}
              exact
              path="/svinehold"
              component={RiskScoringView}
            />
            <PrivateRoute
              user={user}
              exact
              path="/storfe"
              component={RiskScoringView}
            />
            <PrivateRoute
              user={user}
              exact
              path="/smafe"
              component={RiskScoringView}
            />

            <PrivateRoute path="/about" user={user} component={About} />

            <Route exact path="/access-denied" component={AccessDeniedRoute} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/login/callback" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/logout/callback" component={LogoutCallback} />
          </div>
        </Router>
      </ThemeProvider>
    </div>
  );
};

config$.pipe(map(({ environment }) => environment)).subscribe((env) => {
  store.dispatch(setEnvironment(env));
});

const App = () => {
  const env = useTypedSelector((state) => state.ui.env);
  const username = useTypedSelector((state) => state.user.username);

  return <AppView user={username} env={env} />;
};

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
