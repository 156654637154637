import React, { useContext } from "react";
import styled from "styled-components";
import { IThemeColors } from "@mattilsynet/mt-ui/dist/assets/colors";
import { FilterIcon, ThemeContext } from "@mattilsynet/mt-ui";

const StyledDiv = styled.div<{
  noWrap: boolean | undefined;
  theme: IThemeColors;
}>`
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : undefined)};

  &.filter-is-active .i-icon {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
  }
`;

interface IFilterActiveHeaderDisplayProps {
  isFiltered?: boolean;
  showTextWhenInactive?: boolean;
  children?: string;
  noWrap?: boolean;
}

const FilterActiveHeaderDisplay = ({
  isFiltered,
  children,
  showTextWhenInactive,
  noWrap,
}: IFilterActiveHeaderDisplayProps) => {
  const theme = useContext(ThemeContext);
  if (!isFiltered && !showTextWhenInactive) return null;
  return (
    <StyledDiv theme={theme} noWrap={noWrap} className="filter-is-active">
      {isFiltered && <div className="i-icon">i</div>}
      <div className="text">{children}</div>
    </StyledDiv>
  );
};

const StyledAccordionHeader = styled.div<{ theme: IThemeColors }>`
  &.filter-accordion-header .accordion-title {
    color: ${({ theme }) => theme.icons};
  }
`;

export const FilterAccordionHeader = ({
  isFiltered,
  antallMeldingerText,
}: {
  isFiltered: boolean;
  antallMeldingerText: string;
}) => {
  const theme = useContext(ThemeContext);
  return (
    <StyledAccordionHeader theme={theme} className="filter-accordion-header">
      <FilterIcon color={theme.icons} size={"medium"} />
      <div className="accordion-title">Filtrering</div>
      <FilterActiveHeaderDisplay isFiltered={isFiltered} showTextWhenInactive>
        {antallMeldingerText}
      </FilterActiveHeaderDisplay>
    </StyledAccordionHeader>
  );
};
