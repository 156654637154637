import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { IHeader } from "@mattilsynet/mt-ui/dist/components/table";
import {
  ExpandArrow,
  Row,
  TableCell,
  TableRow,
  TextLink,
  Text,
  LoadingDots,
  ErrorBox,
} from "@mattilsynet/mt-ui";
import dayjs from "dayjs";
import { TilsynobjektHistorikkState, Tilsynsobjekt } from "../../model/type";
import { IStoreState } from "../../reducers/types";
import { TilsynsobjektRowShowMore } from "../tilsynsobjekt-row-show-more";

export const TilsynsobjektRow = ({
  index,
  tilsynsobjekt,
  openRows,
  toggleOpenRow,
  tableHeader,
  onFordelTilKollega,
}: {
  index: number;
  tilsynsobjekt: Tilsynsobjekt;
  openRows: TilsynobjektHistorikkState["openRows"];
  toggleOpenRow: (tilsynsObjektId?: string) => void;
  tableHeader: IHeader[];
  onFordelTilKollega: (tilsynsobjektId: string) => void;
}) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1023px)" });

  const showMore = openRows.some((id) => id === tilsynsobjekt.tilsynsobjektId);

  const backgroundColor = index % 2 === 0 ? "bg-gray" : "bg-white";

  const { sisteTilsyn, sisteTilsynLoading, sisteTilsynOk } = useSelector(
    (state: IStoreState) => state.sisteTilsyn
  );

  const renderDate = (tilsynsobjektId: string) => {
    const t = sisteTilsyn.filter(
      (t) => t.tilsynsobjektId == tilsynsobjektId
    )[0];

    if (t == null) {
      return "";
    }

    if (t.tilsynDate == null || t.tilsynDate == "") {
      return "";
    }

    return dayjs(t.tilsynDate).format("D. MMM YYYY");
  };

  return (
    <React.Fragment key={tilsynsobjekt.tilsynsobjektId}>
      <TableRow
        key={tilsynsobjekt.tilsynsobjektId}
        header={tableHeader.map((item) => String(item.display))}
        className={backgroundColor}
      >
        <TableCell>{tilsynsobjekt.riskScoring.toFixed(2)}</TableCell>
        <TableCell>
          {sisteTilsynLoading.includes(tilsynsobjekt.tilsynsobjektId) ? (
            <LoadingDots />
          ) : sisteTilsynOk.includes(tilsynsobjekt.tilsynsobjektId) ? (
            renderDate(tilsynsobjekt.tilsynsobjektId)
          ) : (
            ""
          )}
        </TableCell>
        <TableCell>{tilsynsobjekt?.name || ""}</TableCell>
        <TableCell>{tilsynsobjekt.avdeling || ""}</TableCell>

        <TableCell className="show-details">
          <Row>
            <TextLink
              onClick={() => toggleOpenRow(tilsynsobjekt.tilsynsobjektId)}
              small
            >
              {showMore ? "Skjul detaljer" : "Vis detaljer"}
            </TextLink>
            <ExpandArrow
              rotateDirection={showMore ? "UP" : "DOWN"}
              size="medium"
              onClick={() => toggleOpenRow(tilsynsobjekt.tilsynsobjektId)}
              cursor="pointer"
            />
          </Row>
        </TableCell>
      </TableRow>
      {showMore && (
        <TilsynsobjektRowShowMore
          isSmallScreen={isSmallScreen}
          onFordelTilKollega={onFordelTilKollega}
          avdeling={tilsynsobjekt.avdeling}
          tilsynsobjektId={tilsynsobjekt.tilsynsobjektId}
          backgroundColor={backgroundColor}
        />
      )}
    </React.Fragment>
  );
};
