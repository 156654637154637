export const TilsynsobjektRowActions = {
    OPEN_ROW: "OPEN_ROW",
    CLOSE_ROW: "CLOSE_ROW",
}

export const openRowAction = (
    tilsynsObjektId: string
    ) => ({
    type: TilsynsobjektRowActions.OPEN_ROW,
    tilsynsObjektId
})

export const closeRowAction = (
    tilsynsObjektId: string
) => ({
    type: TilsynsobjektRowActions.CLOSE_ROW,
    tilsynsObjektId
})
