import { RiskScoringDispatchType, RiskScoringAction, RiskScoringMetaData, PageMetadata, Tilsynsobjekt } from '../../model/type'
import * as actionTypes from './actionTypes';

export const loadingRiskScoringData = () => {
  const action: RiskScoringAction = {
      type: actionTypes.LOADING_RISKSCORINGS,
      currentRiskScoringData: [],
      loading: true
  }

  return (dispatch: RiskScoringDispatchType) => {
    dispatch(action);
  }
}

export const loadRiskScoringData = (
  riskScoringDataList: Tilsynsobjekt[],
  pageMetaData: PageMetadata
) => {
    const action: RiskScoringAction = {
        type: actionTypes.LOAD_RISKSCORINGS,
        currentRiskScoringData: riskScoringDataList,
        loading: false,
        pageMetaData
    }

    return (dispatch: RiskScoringDispatchType) => {
      dispatch(action);
    }
}

export const addRiskScoringData = (riskScoringData: RiskScoringMetaData) => {
  const action: RiskScoringAction = {
      type: actionTypes.ADD_RISKSCORINGS,
      currentRiskScoringData: riskScoringData.riskScoringDataList,
      loading: false,
  }

  return (dispatch: RiskScoringDispatchType) => {
    dispatch(action);
  }
}
