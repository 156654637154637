import { combineEpics } from "redux-observable";

import userEpics from "./user-epic";
import { catchError } from "rxjs/operators";
import { apm } from "@elastic/apm-rum";
import { notifications } from "@mattilsynet/mt-common";

const authTokenPath = ["user", "accessToken"];

/**
 * The file that connects ALL the epics
 *
 */
const rootEpic = (action$, store$, dependencies) =>
  combineEpics(
    userEpics,
    notifications.epic(authTokenPath, "/api/notification-api")
  )(action$, store$, dependencies).pipe(
    catchError((err, source) => {
      apm.captureError(err);
      return source;
    })
  );

export default rootEpic;
