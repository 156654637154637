import * as R from 'ramda'

import { authSubject } from '../auth/keycloak'
import {
  throwError,
  Observable,
  OperatorFunction,
  mergeMap,
  map,
  of,
  scan,
  retryWhen,
  delay,
} from 'rxjs'
import { AjaxRequest, AjaxError, ajax } from 'rxjs/ajax'

export const getToken = () =>
  mergeMap(() => authSubject.pipe(mergeMap((keycloak) => keycloak.getToken())))

export const withAccessTokenHeader = (): OperatorFunction<
  AjaxRequest,
  AjaxRequest
> =>
  mergeMap((ajaxObject) => {
    return of(ajaxObject).pipe(
      getToken(),
      map((accessToken: string) => ({
        ...ajaxObject,
        withCredentials: true,
        headers: {
          ...ajaxObject.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      }))
    )
  })

export const withJsonHeader = () =>
  map(
    (ajaxObject: AjaxRequest): AjaxRequest => ({
      ...ajaxObject,
      headers: {
        ...ajaxObject.headers,
        'Content-Type': 'application/json',
      },
      responseType: 'json',
    })
  )

const retryCount = (err$: Observable<AjaxError>): Observable<AjaxError[]> =>
  err$.pipe(
    scan((errAcc, err) => {
      if (errAcc.length > 2) {
        throw err
      }
      return [...errAcc, err]
    }, [])
  )

/**
 * TODO: Document
 *
 */
export const handleUnauthorized = (scheduler = undefined) =>
  retryWhen((error$: Observable<AjaxError>) => {
    return error$.pipe(
      retryCount,
      map<AjaxError[], AjaxError>(R.last),
      mergeMap((err: AjaxError) => {
        return throwError(err)
      }),
      delay(500, scheduler)
    )
  })

export const toAjaxRequest = () =>
  mergeMap((ajaxObject: AjaxRequest) => ajax(ajaxObject))
