import { initialState } from './initialState'
import { UserActions } from '../../actions/userActions'
import { IUserState } from './types'

const userReducer = (state = initialState, action): IUserState => {
  switch (action.type) {
    case UserActions.SET_USER:
      return {
        ...state,
        username: action.username,
        name: action.name,
        title: action.title,
      }
    case UserActions.IMAGES_LOADED:
      return {
        ...state,
        imagesLoaded: true,
      }
    case UserActions.SET_LAST_LOCATION:
      return {
        ...state,
        lastLocation: action.lastLocation,
      }
    case UserActions.FETCH_USER_ORGENHET_OK:
      return {
        ...state,
        userOrgenhet: action.data,
      }
    case UserActions.FETCH_USER_AVDELING_OK:
      return {
        ...state,
        userAvdeling: action.data,
      }
    case UserActions.UPDATE_TOKEN:
      return {
        ...state,
        accessToken: action.data,
      }
    default:
      return state
  }
}

export default userReducer
