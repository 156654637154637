import { createQueryStringFromObject } from "../common/createQueryStringFromObject";
import { PageMetadata, RiskScoringMetaData } from "../model/type";

export const fetchSvinehold = (accessToken: string, pageMetaData: PageMetadata, onSuccess: (data: RiskScoringMetaData, page: PageMetadata) => void) => {

    const pageNumber =
        pageMetaData?.number != null ? pageMetaData?.number + 1 : 0
    const pageSize = pageMetaData?.size != null ? pageMetaData?.size : 1000

    fetch(`${process.env.REACT_APP_DRISK_SVINEHOLD_URL}${createQueryStringFromObject('?')({
        'page.size': pageSize,
        'page.number': pageNumber,
      })}` as string, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
    })
    .then(response => response.json())
    .then((data) => {
        onSuccess(data._embedded, data.page);
    })
    .catch((error) => {
        console.error('Error: ', error);
    });
}

export default {
    fetchRiskScoringData: fetchSvinehold,
  }