import { createQueryStringFromObject } from "@mattilsynet/mt-common/lib/utils/createQueryStringFromObject";

export const fetchAnsatteApi = (accessToken: string, avdeling: string, onSuccess: (ansatte) => void, onError: () => void) => {
  
  const pageSize = 10000
  const queries = createQueryStringFromObject('?')({
    'page.size': pageSize,
    'page.number': 0,
  })

  fetch(`${process.env.REACT_APP_DRISK_BASE_URL}/api/ansatte/${avdeling}/ansatte${queries}`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
      }
  })
  .then(response => {
    if(response.status >= 200 && response.status < 300) {
      return response.json();
    } else {
      onError();
    }
  })
  .then(data => {
      onSuccess(data._embedded.ansattList);
  })
  .catch((error) => {
      onError();
  });
}

export const fetchAllAnsatteApi = (accessToken: string, avdeling: string, onSuccess: (ansatte) => void, onError: () => void) => {
  
  const pageSize = 10000
  const queries = createQueryStringFromObject('?')({
    'page.size': pageSize,
    'page.number': 0,
  })

  fetch(`${process.env.REACT_APP_DRISK_BASE_URL}/api/all-ansatte${queries}`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
      }
  })
  .then(response => {
    if(response.status >= 200 && response.status < 300) {
      return response.json();
    } else {
      onError();
    }
  })
  .then(data => {
      onSuccess(data._embedded.ansattList);
  })
  .catch((error) => {
      onError();
  });
}


export default {
  fetchAnsatte: fetchAnsatteApi,
  fetchAllAnsatteApi
}