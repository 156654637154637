import {
  Grid,
  GridItem,
  Column,
  TabBar,
} from '@mattilsynet/mt-ui'
import React from 'react';
import { useHistory } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import RiskScoringTable from '../../components/riskscoringtable/RiskScoringTable';
import { fetchSvinehold } from '../../api/riskscoring';

enum Tabs {
  SVINEHOLD = '/svinehold',
  STORFE = '/storfe',
  SMAFE = '/smafe',
}

export const RiskScoringView = ({ match }: { match: any }) => {
 
  const history = useHistory();

  const goToPath = (path: string) => history.push(path);

  const tabOptions = [
    { display: 'Svinehold', value: Tabs.SVINEHOLD },
    { display: 'Storfe', value: Tabs.STORFE },
    { display: 'Småfe', value: Tabs.SMAFE },
  ]

  return (
    <Grid>
      <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>

        <Column spacing={4}>
          <Column padding={[1, 0, 0, 0]}>
            <TabBar
              options={tabOptions}
              selectedValue={match.path}
              onClick={goToPath}
              forceShowAsDropdown={false}
              size="normal"
            /> 
          </Column>

          <Switch>
            <Route
              exact
              path={Tabs.SVINEHOLD}
              render={(props) => (
                <RiskScoringTable
                  {...props}
                  fetchData={fetchSvinehold}
                />
              )}
            />
          </Switch>

        </Column>
      </GridItem>
    </Grid>
  )
}
