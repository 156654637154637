import React, {  } from 'react'

import { AboutView } from './view'
import { useHistory } from 'react-router'

const About = () => {
  const history = useHistory();

  const goBackOnclick = () => history.push("/");

  return <AboutView goBack={goBackOnclick} />
}

export default About
