import React, { ComponentProps, SetStateAction, useState } from "react";
import dayjs from "dayjs";
import { useMediaQuery } from "react-responsive";
import {
  Accordion,
  CheckboxItem,
  Column,
  DatePicker,
  Row,
  ShadowBox,
  Text,
  TextLink,
} from "@mattilsynet/mt-ui";
import { FilterAccordionHeader } from "./filter-accordion-header";
import { PageMetadata } from "../../model/type";
import { FiltreringDateButtons } from "./filtrering-date-buttons";
import { CalendarInput } from "./calendar-input";
import "./style.css";

interface IFiltreringProps {
  pageMetaData: PageMetadata;
  filterSisteTilsynDate: string;
  setFilterSistetilsynDate: React.Dispatch<SetStateAction<string>>;
  setFilterPlannedOrOngoingTilsynskvittering: React.Dispatch<
    SetStateAction<boolean>
  >;
  currentTotalShown: () => number;
}

export const Filtrering = ({
  pageMetaData,
  filterSisteTilsynDate,
  setFilterSistetilsynDate,
  setFilterPlannedOrOngoingTilsynskvittering,
  currentTotalShown,
}: IFiltreringProps) => {
  const isTinyScreen = useMediaQuery({ query: "(max-width: 767px)" });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [showDatePicker, setShowDatePicker] = useState(false);

  const [
    isFilterPlannedOrOngoingTilsynskvitteringCheckboxChecked,
    setIsFilterPlannedOrOngoingTilsynskvitteringCheckboxChecked,
  ] = useState(false);

  const toggleFilterPlannenOrOngoingTilsynskvittering = () => {
    setIsFilterPlannedOrOngoingTilsynskvitteringCheckboxChecked(
      (prev) => !prev
    );
    setFilterPlannedOrOngoingTilsynskvittering((prev) => !prev);
  };

  let antallMeldingerText = "";
  if (
    pageMetaData.size != null &&
    pageMetaData.number != null &&
    pageMetaData.totalElements != null
  ) {
    let antallMeldingerSomVises = pageMetaData.size * (pageMetaData.number + 1);
    const totaltAntallMeldinger = pageMetaData.totalElements;
    if (totaltAntallMeldinger < antallMeldingerSomVises) {
      antallMeldingerSomVises = totaltAntallMeldinger;
    }
    antallMeldingerText = `Viser ${currentTotalShown()} av ${
      pageMetaData.totalElements
    } tilsynsobjekter`;
  }

  const onChangeTilsynsdato = (date: string) => {
    setFilterSistetilsynDate(date);
  };

  const RowOrColumn = (
    props: ComponentProps<typeof Column> | ComponentProps<typeof Row>
  ) => (isTinyScreen ? <Column {...props} /> : <Row {...props} />);

  return (
    <ShadowBox>
      <Accordion
        small
        open={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        header={
          <FilterAccordionHeader
            isFiltered={false}
            antallMeldingerText={antallMeldingerText}
          />
        }
      >
        <Column spacing={1}>
          <RowOrColumn spacing={1}>
            <Text weight="bold">
              Ikke vis Tilsynsobjekt hvor det har vært gjennomført Tilsyn de
              siste:
            </Text>
          </RowOrColumn>
          <RowOrColumn align="center">
            <FiltreringDateButtons
              filterSisteTilsynDate={filterSisteTilsynDate}
              showDatePicker={showDatePicker}
              setFilterSistetilsynDate={setFilterSistetilsynDate}
              setShowDatePicker={setShowDatePicker}
            />
          </RowOrColumn>
          {showDatePicker && (
            <RowOrColumn>
              <DatePicker
                ariaLabel="date"
                date={filterSisteTilsynDate || dayjs().toISOString()}
                setDate={onChangeTilsynsdato}
                minDate={dayjs().subtract(7, "day").toISOString()}
                maxDate={dayjs().toISOString()}
                customInput={<CalendarInput dato={filterSisteTilsynDate} />}
              />
            </RowOrColumn>
          )}
          <Row>
            <CheckboxItem
              onClick={toggleFilterPlannenOrOngoingTilsynskvittering}
              selected={
                isFilterPlannedOrOngoingTilsynskvitteringCheckboxChecked
              }
            >
              Ikke vis Tilsynsobjekt hvor det er planlagt eller pågår
              tilsynskvittering
            </CheckboxItem>
          </Row>
        </Column>

        <Row justify="flex-end" margin={[1, 1]}>
          <TextLink small onClick={() => setFilterSistetilsynDate(undefined)}>
            Fjern alle filtre
          </TextLink>
        </Row>
      </Accordion>
    </ShadowBox>
  );
};
