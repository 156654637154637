import { TypedUseSelectorHook, useSelector } from "react-redux";
import { IStoreState } from "../reducers/types";
import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as R from "ramda";
import history from "../history";

export const useTypedSelector: TypedUseSelectorHook<IStoreState> = useSelector;

export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(timeOut);
  }, [delay, value]);
  return debouncedValue;
};

export const useDeepEffect = (
  effectCallback: EffectCallback,
  deps: DependencyList
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectCallback, useDeepCompareMemoize(deps));
};

const useDeepCompareMemoize = (deps) => {
  const ref = useRef();

  if (!R.equals(deps, ref.current)) {
    ref.current = deps;
  }

  return ref.current;
};

export const usePaginatedList = <T>(
  data: T[],
  pageSize: number
): [
  currentPage: T[],
  totalPages: number,
  pageNumber: number,
  setPage: React.Dispatch<React.SetStateAction<number>>
] => {
  const [page, setPage] = useState(0);

  const paginatedList = useMemo(
    () =>
      data.reduce((res, item, index) => {
        const page = Math.floor(index / pageSize);
        res[page] = [...(res[page] ?? []), item];
        return res;
      }, [] as T[][]),
    [data, pageSize]
  );

  const currentPage = useMemo(
    () => paginatedList[page] ?? [],
    [page, paginatedList]
  );

  useDeepEffect(() => setPage(0), [data]);

  return [currentPage, paginatedList.length, page, setPage];
};

export const useActionOnBackButton = (
  actionOnBackButton: () => void,
  isActive: boolean
) => {
  return useEffect(() => {
    if (isActive) {
      return history.block((_location, action) => {
        if (action === "POP") {
          actionOnBackButton?.();
          return false;
        }
      });
    }
  }, [isActive, actionOnBackButton]);
};

export const useLoadOnScrollBottom = (
  fetch: () => void,
  isLoading: boolean
) => {
  const { number: currentPage, totalPages } = useTypedSelector(
    (state: IStoreState) => state.svinehold.pageMetaData
  );

  const scrollHandler = useCallback(() => {
    const canLoad =
      currentPage !== undefined &&
      totalPages !== undefined &&
      currentPage < totalPages - 1;

    const shouldNotLoad =
      window.innerHeight + document.documentElement.scrollTop <
        document.documentElement.offsetHeight - 10 ||
      !canLoad ||
      isLoading;

    if (shouldNotLoad) {
      return;
    }

    fetch();
  }, [currentPage, totalPages]);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [scrollHandler]);
};
