import { IToast } from '@mattilsynet/mt-common'

export const cantArchiveToast = (): IToast => ({
  text: 'Kan ikke arkivere bilder med mangler',
  type: 'DANGER',
  dismissible: true,
  timeoutSeconds: 5,
})

export const online = (): IToast => ({
  text: 'Applikasjonen er <b>online</b>, og alle funksjoner er tilgjengelig.',
  timeoutSeconds: 5,
  type: 'SUCCESS',
  dismissible: true,
})

export const offline = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. \n' +
    '\n' +
    'Du kan derfor ikke knytte bilder til sak. \n',
  timeoutSeconds: 10,
  type: 'WARN',
  dismissible: true,
})

export const couldNotSync = (): IToast => ({
  text: 'Kunne ikke synkronisere bilder. Viser bare lokale bilder.',
  timeoutSeconds: 10,
  type: 'WARN',
  dismissible: true,
})

export const couldNotLogOut = (): IToast => ({
  text: 'Kunne ikke logge ut, applikasjonen er offline.',
  timeoutSeconds: 10,
  type: 'WARN',
  dismissible: true,
})

export const archiveFailed = (): IToast => ({
  text: 'Kan ikke arkivere bilder',
  type: 'WARN',
  dismissible: true,
  timeoutSeconds: 5,
})

export const missingSaknummer = (): IToast => ({
  text: 'Mangler gyldig saksnummer',
  type: 'WARN',
  dismissible: true,
  timeoutSeconds: 5,
})

export const missingValidSak = (): IToast => ({
  text: 'Du må hente en åpen sak',
  type: 'WARN',
  dismissible: true,
  timeoutSeconds: 5,
})

export const missingHoveddokumentId = (): IToast => ({
  text: 'Du må velge et hoveddokument som bildene skal knyttes mot',
  type: 'WARN',
  dismissible: true,
  timeoutSeconds: 5,
})

export const takePictureFailedToast = (text: string): IToast => ({
  text: text,
  type: 'WARN',
  dismissible: true,
  timeoutSeconds: 5,
})

export const validatingImagesFailedToast = (): IToast => ({
  text: 'Kunne ikke validere bilder',
  type: 'DANGER',
  dismissible: true,
  timeoutSeconds: 5,
})

export const cantSendDiagnostikkData = (): IToast => ({
  text: 'Du må ha lest personvernerklæringen før du kan sende diagnostikkdata.',
  type: 'WARN',
  dismissible: true,
  timeoutSeconds: 5,
})

export const diagnosticDataSent = (): IToast => ({
  text: 'Diagnostikkdata er sendt.',
  type: 'SUCCESS',
  dismissible: true,
  timeoutSeconds: 5,
})

export const tilsynPlannedSuccess = (): IToast => ({
  text: 'Forberedt tilsynskvittering er opprettet',
  type: 'SUCCESS',
  dismissible: true,
  timeoutSeconds: 5,
})

export const tilsynPlannedError = (): IToast => ({
  text: 'Tilsynskvittering kunne ikke opprettes',
  type: 'DANGER',
  dismissible: true,
  timeoutSeconds: 5,
})

export const noColleagueSelectedError = (): IToast => ({
  text: 'Du må velge en kollega å fordele tilsynskvitteringen til',
  type: 'DANGER',
  dismissible: true,
  timeoutSeconds: 5,
})

export const noRiskScoringWithinArea = (): IToast => ({
  text: `Vi fant ingen tilsynsobjekter under din avdeling. Kontakt brukerstøtte om du trenger å se tilsynsobjekter for hele regionen eller hele landet.`,
  type: 'WARN',
  dismissible: true,
  timeoutSeconds: 20,
})
