interface ITidligereTilsynDTO {
  ansattNavn: string;
  antallBilder: number;
  status: TidligereTilsynStatus;
  tilsynsdato: string;
  externalTilsynsobjektId: string;
  tilsynskvitteringId: string;
  userId: string;
}

export enum TidligereTilsynStatus {
  PAABEGYNT = "PAABEGYNT",
  FORBEREDT = "FORBEREDT",
  FERDIGSTILT = "FERDIGSTILT",
  ARKIVERT = "ARKIVERT",
}

export interface ITidligereTilsynskvitteringer {
  tilsynsobjektId: string;
  tilsynskvitteringer: ITidligereTilsyn[];
}

export enum TidligereTilsynActionTypes {
  GET_TIDLIGERE_TILSYN = "GET_TIDLIGERE_TILSYN",
  TIDLIGERE_TILSYN_OK = "TIDLIGERE_TILSYN_OK",
  TIDLIGERE_TILSYN_FAIL = "TIDLIGERE_TILSYN_FAIL",
}

export interface ITidligereTilsyn {
  tilsynskvitteringId: string;
  ansattNavn: string;
  status: TidligereTilsynStatus;
  tilsynsdato: string;
}

export interface ITidligereTilsynState {
  tidligereTilsynLoading: boolean;
  tidligereTilsynFail: boolean;
  tidligereTilsyn: ITidligereTilsynskvitteringer[];
}

export interface ITidligereTilsynAction {
  type: TidligereTilsynActionTypes;
  tilsynsobjektIds?: string[];
  tidligereTilsyn?: ITidligereTilsynskvitteringerResponse[];
}

export interface ITidligereTilsynskvitteringerResponse {
  tilsynsobjektId: string;
  tilsynskvitteringer: ITidligereTilsynDTO[];
}
