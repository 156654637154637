import { useSelector } from "react-redux";
import { IStoreState } from "../../reducers/types";
import {
  IProduksjonsform,
  IProduksjonsformItem,
} from "../../reducers/produksjonsformerReducer/types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { produksjonsformerActions } from "../../reducers/produksjonsformerReducer";
import { fetchProduksjonsformer } from "../../api/produksjonsformer";
import { tidligereTilsynActions } from "../../reducers/tidligereTilsynReducer";
import { getTidligereTilsyn } from "../../api/tilsyn";
import { ITidligereTilsynskvitteringerResponse } from "../../reducers/tidligereTilsynReducer/types";

export {
  useProduksjonsformer,
  useTidligereTilsynskvitteringer,
  useTilsynsobjektinfoUrl,
};

const useProduksjonsformer = (tilsynsobjektId: string, accessToken: string) => {
  const {
    tilsynsobjektProduksjonsformerFail,
    tilsynsobjektProduksjonsformerLoading,
    tilsynsobjektProduksjonsformer,
  } = useSelector((state: IStoreState) => state.produksjonsformerState);

  const dispatch = useDispatch();

  const produksjonsformer = tilsynsobjektProduksjonsformer.find(
    (item: IProduksjonsformItem) => item.tilsynsobjektId === tilsynsobjektId
  );

  const onSuccess = (data: IProduksjonsform[]) => {
    dispatch(
      produksjonsformerActions.getProduksjonsformerOk(tilsynsobjektId, data)
    );
  };

  const onError = () =>
    dispatch(produksjonsformerActions.getProduksjonsformerFail());

  useEffect(() => {
    if (!produksjonsformer) {
      dispatch(produksjonsformerActions.getProduksjonsformer());
      fetchProduksjonsformer(tilsynsobjektId, accessToken, onSuccess, onError);
    }
  }, [produksjonsformer]);

  return {
    produksjonsformer,
    tilsynsobjektProduksjonsformerFail,
    tilsynsobjektProduksjonsformerLoading,
  };
};

const useTidligereTilsynskvitteringer = (
  tilsynsobjektId: string,
  accessToken: string
) => {
  const { tidligereTilsyn, tidligereTilsynLoading, tidligereTilsynFail } =
    useSelector((state: IStoreState) => state.tidligereTilsynskvitteringer);

  const dispatch = useDispatch();

  const tilsyn = tidligereTilsyn?.find(
    (tilsyn) => tilsyn.tilsynsobjektId === tilsynsobjektId
  );

  const tidligereTilsynskvitteringer = tilsyn?.tilsynskvitteringer;

  const onSuccess = (data: ITidligereTilsynskvitteringerResponse[]) => {
    dispatch(tidligereTilsynActions.tidligereTilsynOk(data, [tilsynsobjektId]));
  };

  const onError = () => {
    dispatch(tidligereTilsynActions.tidligereTilsynFail());
  };

  useEffect(() => {
    if (
      !tidligereTilsynskvitteringer ||
      tidligereTilsynskvitteringer.length === 0
    ) {
      dispatch(tidligereTilsynActions.getTidligereTilsyn());
      getTidligereTilsyn(accessToken, [tilsynsobjektId], onSuccess, onError);
    }
  }, [tidligereTilsynskvitteringer]);

  return {
    tidligereTilsynskvitteringer,
    tidligereTilsynLoading,
    tidligereTilsynFail,
  };
};

const useTilsynsobjektinfoUrl = (tilsynsobjektId: string) => {
  const env = useSelector((state: IStoreState) => state.ui.env);

  const baseEnv = env === "prod" ? "" : env === "dev" ? "-utv" : `-${env}`;

  return `https://tilsynsobjektinfo.inspektor${baseEnv}.mattilsynet.io/${tilsynsobjektId}`;
};
