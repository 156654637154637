import { Tilsynsobjekt } from "../model/type";
import { SisteTilsyn } from "../reducers/sisteTilsynReducer/types";
import {
  ITidligereTilsynskvitteringer,
  TidligereTilsynStatus,
} from "../reducers/tidligereTilsynReducer/types";
import RiskScoringData from "../test/data/riskscoring.json";

export const RiskScoringMockData: any = () => {
  return RiskScoringData;
};

export const sortByRiskScoring: any = (riskScoringData: Tilsynsobjekt[]) => {
  return Array.from(riskScoringData).sort((a, b) =>
    a.riskScoring < b.riskScoring ? 1 : -1
  );
};

export const filterRiskScoringBySisteTilsyn = (
  tilsynsobjekt: Tilsynsobjekt,
  sisteTilsyn: SisteTilsyn[],
  sisteTilsynDato: string
): boolean => {
  const t = sisteTilsyn.filter(
    (t) => t.tilsynsobjektId == tilsynsobjekt.tilsynsobjektId
  )[0];

  return (
    sisteTilsynDato == undefined ||
    t == undefined ||
    t.tilsynDate == undefined ||
    sisteTilsynDato.localeCompare(t.tilsynDate) > 0
  );
};

export const filterRiskScoringByNotPlannedOrOngoingTilsyn = (
  tilsynsobjekt: Tilsynsobjekt,
  tidligereTilsyn: ITidligereTilsynskvitteringer[],
  shouldFilter: boolean
) => {
  const tilsynsobjektId = tilsynsobjekt.tilsynsobjektId;

  const tidligereTilsynskvitteringer = tidligereTilsyn.find(
    (tilsyn) => tilsyn.tilsynsobjektId === tilsynsobjektId
  )?.tilsynskvitteringer;

  const hasNoPlannedOrOngoingTilsyn = !tidligereTilsynskvitteringer?.some(
    (tilsyn) =>
      tilsyn.status === TidligereTilsynStatus.PAABEGYNT ||
      tilsyn.status === TidligereTilsynStatus.FORBEREDT
  );

  if (shouldFilter) {
    return hasNoPlannedOrOngoingTilsyn;
  }

  return tilsynsobjekt;
};

export * as utils from "./utils";
