import { IVirksomhetAdresse } from "../model/type"

export const AddressActions = {
    LOAD_ADDRESS: "LOAD_ADDRESS",
    LOAD_ADDRESS_OK: "LOAD_ADDRESS_OK",
    LOAD_ADDRESS_FAIL: "LOAD_ADDRESS_FAIL"
}

export const fetchVirksomhetsAddress = (
    tilsynsObjektId: string
) => ({
    type: AddressActions.LOAD_ADDRESS,
    tilsynsObjektId
})

export const loadAddressOk = (
    tilsynsObjektId: string,
    address: IVirksomhetAdresse) => 
    ({
    type: AddressActions.LOAD_ADDRESS_OK,
    tilsynsObjektId,
    address
})

export const loadAddressFail = (
    tilsynsObjektId: string
) => ({
    type: AddressActions.LOAD_ADDRESS_FAIL,
    tilsynsObjektId
})
