import { IVirkomhetActions } from "@mattilsynet/mt-common/lib/brreg/store/types";
import { AddressActions } from "../../actions/addressActions";
import initialState from "./initialState";
import { AddressAction, AddressState } from "./types";
import { IVirksomhetAdresse } from "../../model/type";

const addressReducer = (
    state = initialState,
    action: AddressAction
  ): AddressState => {
    switch (action.type) {
      case AddressActions.LOAD_ADDRESS:
        return {
          ...state,
          addressLoading: [...state.addressLoading, action.tilsynsObjektId]
        }

      case AddressActions.LOAD_ADDRESS_OK:
        return {
          ...state,
          addressLoading: state.addressLoading.filter(tilsynsObjektId => tilsynsObjektId != action.tilsynsObjektId),
          addressOk: [...state.addressOk, action.tilsynsObjektId],
          addressFail: state.addressFail.filter(tilsynsObjektId => tilsynsObjektId != action.tilsynsObjektId),
          addresses: [...state.addresses,  {
            ...action.address,
            tilsynsobjektId: action.tilsynsObjektId
          } as IVirksomhetAdresse]
        }

      case AddressActions.LOAD_ADDRESS_FAIL:
        return {
          ...state,
          addressLoading: state.addressLoading.filter(tilsynsObjektId => tilsynsObjektId != action.tilsynsObjektId),
          addressFail: [...state.addressFail, action.tilsynsObjektId],
        }
    }
    return state
  };

  export default addressReducer