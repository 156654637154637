export const TilsynActions = {
    PLAN_TILSYN_LOADING: "PLAN_TILSYN_LOADING",
    PLAN_TILSYN_OK: "PLAN_TILSYN_OK",
    PLAN_TILSYN_FAIL: "PLAN_TILSYN_FAIL"
}

export const planTilsynAction = (
    tilsynsObjektId: string
) => ({
    type: TilsynActions.PLAN_TILSYN_LOADING,
    tilsynsObjektId
})

export const planTilsynOk = (
    tilsynsObjektId: string) => 
    ({
    type: TilsynActions.PLAN_TILSYN_OK,
    tilsynsObjektId
})

export const planTilsynFail = (
    tilsynsObjektId: string
) => ({
    type: TilsynActions.PLAN_TILSYN_FAIL,
    tilsynsObjektId
})
