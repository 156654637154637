import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { UserMenu as UserMenuMtUi, TextLink, Row } from '@mattilsynet/mt-ui'
import { IStoreState } from '../../reducers/types'

const UserMenuView = ({
  avdelingName,
  goToAbout,
  isOpen,
  logout,
  name,
  onCloseMenuOnBlur,
  title,
  toggleMenu,
  userMenuRef,
  calculateTilsynskvitteringPath,
}) => (
    <Row
      className="user-menu-container"
      onBlur={onCloseMenuOnBlur}
      ref={userMenuRef}
    >
      <UserMenuMtUi
        isOpen={isOpen}
        onClick={toggleMenu}
        name={name}
        title={title || ''}
        division={avdelingName}
      >
        <div className='link-group'>
          <TextLink href={calculateTilsynskvitteringPath} linkOutside>
            Gå til Tilsynskvittering
          </TextLink>
          <TextLink onClick={goToAbout}>Om applikasjonen</TextLink>
        </div>
        <TextLink onClick={logout}>Logg ut</TextLink>
      </UserMenuMtUi>
    </Row>
)


const UserMenu = () => {
  const history = useHistory()
  const { username, name: authName } = useSelector(
    (state: IStoreState) => state.user
  )
  const { name, title } = useSelector(
    (state: IStoreState) => state.user.userOrgenhet
  )
  const { name: avdelingName } = useSelector(
    (state: IStoreState) => state.user.userAvdeling
  )

  const environment = useSelector((state: IStoreState) => state.ui.env)

  const calculateTilsynskvitteringPath =
    environment !== 'prod'
      ? `https://tilsynskvittering.${environment}.k8s.mattilsynet.no/`
      : 'https://tilsynskvittering.app.mattilsynet.no'

  const userMenuRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => setIsOpen(!isOpen)
  const logout = () => history.replace('/logout')
  const goToAbout = () => {
    setIsOpen(!isOpen)
    history.push('/about', { fromPath: history.location.pathname })
  }

  const onCloseMenuOnBlur = (event) => {
    if (
      !event.relatedTarget ||
      !userMenuRef.current.contains(event.relatedTarget)
    ) {
      setIsOpen(false)
    }
  }

  if (username) {
    return (
      <UserMenuView
        avdelingName={avdelingName}
        goToAbout={goToAbout}
        isOpen={isOpen}
        logout={logout}
        name={name || authName || username}
        onCloseMenuOnBlur={onCloseMenuOnBlur}
        title={title}
        toggleMenu={toggleMenu}
        userMenuRef={userMenuRef}
        calculateTilsynskvitteringPath={calculateTilsynskvitteringPath}
      />
    )
  }

  return null
}

export default UserMenu
