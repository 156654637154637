import { ITidligereTilsynskvitteringerResponse } from "../reducers/tidligereTilsynReducer/types";

export const planTilsyn = (
  tilsynsobjektId: string,
  userId: string,
  accessToken: string,
  onSuccess: () => void,
  onError: () => void
) => {
  fetch(`${process.env.REACT_APP_DRISK_BASE_URL}/api/tilsyn`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      externalTilsynsobjektId: tilsynsobjektId,
      userId,
      begrunnelse: "OPPRETTET_I_DRISK",
    }),
  })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        onSuccess();
      } else {
        onError();
      }
    })
    .catch((error) => {
      console.error("Error: ", error);
      onError();
    });
};

export const getSisteTilsyn = (
  accessToken: string,
  tilsynsobjektIds: string[],
  onSuccess: (data: any) => void,
  onError: () => void
) => {
  fetch(`${process.env.REACT_APP_DRISK_BASE_URL}/api/sistetilsyn`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      tilsynsobjektIds,
    }),
  })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json();
      } else {
        onError();
      }
    })
    .then((data) => {
      onSuccess(data);
    })
    .catch((error) => {
      console.error("Error: ", error);
      onError();
    });
};

export const getTidligereTilsyn = (
  accessToken: string,
  tilsynsobjektIds: string[],
  onSuccess: (data: ITidligereTilsynskvitteringerResponse[]) => void,
  onError: () => void
) => {
  return fetch(`${process.env.REACT_APP_DRISK_BASE_URL}/api/tidligere-tilsyn`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      tilsynsobjektIds,
    }),
  })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json();
      } else {
        onError();
      }
    })
    .then((data) => {
      onSuccess(data);
    })
    .catch((error) => {
      console.error("Error: ", error);
    });
};

export default {
  planTilsyn,
  getSisteTilsyn,
};
