import { DiagnosticActions } from '../../actions/diagnosticActions'
import initialState from './initialState'
import { IDiagnosticState } from './types'

const diagnosticReducer = (state = initialState, action): IDiagnosticState => {
  switch (action.type) {
    case DiagnosticActions.DIAGNOSTIC_INITIALIZED:
      return {
        ...state,
        status: 'INITIALIZED',
      }
    case DiagnosticActions.DIAGNOSTIC_SEND:
      return {
        ...state,
        status: 'LOADING',
      }

    default:
      return state
  }
}

export default diagnosticReducer
