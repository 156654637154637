
export const fetchAddress = (accessToken: string, orgnummer: string, onSuccess: (address) => void, onError: () => void) => {
  fetch(`${process.env.REACT_APP_DRISK_BASE_URL}/api/virksomhet?filter.orgnummer=${orgnummer}`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
      }
  })
  .then(response => {
    if(response.status >= 200 && response.status < 300) {
      return response.json();
    } else {
      onError();
    }
  })
  .then(data => {
      onSuccess(data._embedded.virksomheter[0].beliggenhetsadresse);
  })
  .catch((error) => {
      onError();
  });
}

export default {
  fetchAddress,
}