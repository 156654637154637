import { RiskScoringState } from "./types";

const initialState: RiskScoringState = {
    tilsynsobjekter: [],
    plannedTilsyn: [],
    pageMetaData: {
      size: 50
    }
  };

  export default initialState;