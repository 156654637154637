import { couldNotLogOut } from '../../common/toast'
import './style.css'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../actions/userActions'
import { toast } from '@mattilsynet/mt-common'

import { LoadingSpinner, TextLink } from '@mattilsynet/mt-ui'
import { IStoreState } from '../../reducers/types'
import { authSubject } from '../../auth/keycloak'

export const Login = ({ history }) => {
  const dispatch = useDispatch()
  const isOnline = window.navigator.onLine

  useEffect(() => {
    authSubject.subscribe((keycloak) => {
      keycloak.addListener('logout', () => {
        history.push('/logout/callback')
      })

      return keycloak
        .init({ expectedRole: 'drisk-user' })
        .then(({ isAuthenticated, hasRealmAccess }) => {
          if (isAuthenticated && hasRealmAccess) {
            return keycloak.loadUserProfile().then((profile) => {
              dispatch(
                setUser(
                  profile.username,
                  `${profile.lastName} ${profile.firstName}`,
                  'N/A'
                )
              )
              history.push('/svinehold')
            })
          } else if (isAuthenticated) {
            history.push('/access-denied')
          } else if (isOnline) {
            keycloak.redirectToLogin(`${window.location.origin}/login/callback`)
          } else {
            history.push('/offline-login')
          }
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <LoadingSpinner title='Logger inn' />
}

export const Logout = ({ history }) => {
  const dispatch = useDispatch()
  const offline = useSelector((state: IStoreState) => state.ui.offline)

  useEffect(() => {
    authSubject.subscribe((keycloak) => {
      if (offline) {
        history.push('/')
        dispatch(toast.actions.showToast(couldNotLogOut()))
      } else {
        // OBS Dette vil redirect med window.location
        return keycloak.logout('/logout/callback')
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <LoadingSpinner title='Logger ut' />
}

export const LogoutCallback = ({ history }) => (
  <div className='logged-out'>
    <div className='logged-out-text'>Du er logget ut</div>
    <TextLink onClick={() => history.push('/login')}>Logg inn</TextLink>
  </div>
)
