import React, {
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react'
import {
  Column,
  ErrorBox,
  LoadingSpinner,
  Pagination,
  Row,
  SearchInput,
  SelectList,
  Text,
  ToggleSwitch,
} from '@mattilsynet/mt-ui'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import {
  useDebounce,
  usePaginatedList,
  useTypedSelector,
} from '../../common/hooks'
import { ansatteActions, ansatteSelectors } from '../../actions/ansatteActions'
import { ModalWrapper } from '../../components/modal-wrapper'

import './style.css'
import { IStoreState } from '../../reducers/types'
import { toast } from '@mattilsynet/mt-common'
import { noColleagueSelectedError } from '../../common/toast'
import { fetchAllAnsatteApi, fetchAnsatteApi } from '../../api/ansatte'
import { IAnsatt } from '../../reducers/ansattReducer/types'

const PAGE_SIZE = 50

interface IDataListItem {
  value: string
  label: ReactNode
}

const StyledRow = styled(Row)`
  & {
    width: 100%;
  }
  & .Text.ansatt-navn {
    min-width: 250px;
  }
`

export const FordelTilsynTilModal = ({
  onCancel,
  isOpen,
  tilsynsobjektId,
  onSelect
}: {
  onCancel: () => void
  isOpen: boolean
  tilsynsobjektId: string,
  onSelect: (name: string, username: string, tilsynsobjektId: string) => void
}) => {
  const dispatch = useDispatch()
  const isTinyScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const [searchInput, setSearchInput] = useState('')
  const debouncedSearchInput = useDebounce(searchInput, 200)
  const [selectedAnsattBrukernavn, setSelectedAnsattBrukernavn] = useState<
    string | undefined
  >(undefined)
  const isDesktop = useTypedSelector((state: IStoreState) => state.ui.isDesktop)
  const loadingStatus = useTypedSelector(ansatteSelectors.getLoadingStatus)

  const ansatte = useTypedSelector(ansatteSelectors.getAnsatte)
  const searchedAnsatte = ansatte.filter((ansatt) =>
    String(ansatt.navn)
      .toLowerCase()
      .includes(debouncedSearchInput.toLowerCase())
  )
  const [currentPage, totalPages, pageNumber, setPage] = usePaginatedList(
    searchedAnsatte,
    PAGE_SIZE
  )

  const kontor = useTypedSelector(ansatteSelectors.getKontor)

  const dataList = useMemo<IDataListItem[]>(() => {
    const fordeltTil = selectedAnsattBrukernavn ?? ''

    return currentPage.map((ansatt) => ({
      value: ansatt.brukernavn,
      label: (
        <StyledRow justify="space-between" key={ansatt.brukernavn} smColumn>
          <Text
            className={classNames({ 'ansatt-navn': !isTinyScreen })}
            margin={isTinyScreen ? [0, 1, 1, 0] : [0, 1, 0, 0]}
            weight={fordeltTil === ansatt.brukernavn ? 'bold' : 'regular'}
          >
            {ansatt.navn}
          </Text>
          <Text
            weight={fordeltTil === ansatt.brukernavn ? 'bold' : 'regular'}
            margin={isTinyScreen ? [0, 1, 0, 0] : [0, 1, 0, 0]}
          >
            {ansatt.kontorNavn}
          </Text>
        </StyledRow>
      ),
    }))
  }, [selectedAnsattBrukernavn, currentPage, isTinyScreen])

  const [isAlleKolleger, setIsAlleKolleger] = useState(false)

  const { accessToken, userAvdeling } = useSelector(
    (state: IStoreState) => state.user
  )

  const { tilsynLoading } = useSelector(
    (state: IStoreState) => state.tilsyn
  )

  const fetchAnsatte = () => {
    dispatch(ansatteActions.fetchAnsatte());

    fetchAnsatteApi(
      accessToken,
      userAvdeling.id,
      (ansatte: IAnsatt[]) => {
        dispatch(ansatteActions.fetchAnsatteOk(ansatte, { ...userAvdeling }));
      },
      () =>  {
        dispatch(ansatteActions.fetchAnsatteFail("Kunne ikke hente ansatte"));
      }
    )
  }

  const fetchAllAnsatte = () => {
    dispatch(ansatteActions.fetchAllAnsatte());

    fetchAllAnsatteApi(
      accessToken,
      userAvdeling.id,
      (ansatte: IAnsatt[]) => {
        dispatch(ansatteActions.fetchAllAnsatteOk(ansatte, { ...userAvdeling }));
      },
      () =>  {
        dispatch(ansatteActions.fetchAllAnsatteFail("Kunne ikke hente ansatte"));
      }
    )
  }

  const fetchData = useCallback(() => {
      isAlleKolleger
        ? fetchAllAnsatte()
        : fetchAnsatte();
  }, [dispatch, isAlleKolleger])

  const onChangeSearchInput = useCallback(
    (event) => setSearchInput(event.target.value),
    []
  )

  const onToggleAlleKolleger = useCallback(
    () => setIsAlleKolleger(!isAlleKolleger),
    [isAlleKolleger]
  )

  const onSelectKollega = useCallback(
    (username: string) => {
      const name = searchedAnsatte.find(
        (ansatt) => ansatt.brukernavn === username
      )?.navn
      onSelect(name, username, tilsynsobjektId)
    },
    [searchedAnsatte, onSelect]
  )

  const onChangePage = useCallback(
    (pageNumber: number) => setPage(pageNumber),
    [setPage]
  )

  const handleFordelMelding = useCallback(() => {
    const selectedAnsatt = searchedAnsatte.find(
      (ansatt) => ansatt.brukernavn === selectedAnsattBrukernavn
    )
    if (!selectedAnsatt) {
      return dispatch(
        toast.actions.showToast(noColleagueSelectedError())
      )
    }

    onCancel()
  }, [
    searchedAnsatte,
    selectedAnsattBrukernavn,
    dispatch,
    onCancel,
    tilsynsobjektId,
  ])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const renderContent = () => {
    if ((loadingStatus?.loading && !loadingStatus?.loaded) || tilsynLoading.length > 0) {
      return <LoadingSpinner title="" />
    }

    if (loadingStatus?.error) {
      return (
        <ErrorBox
          errorText="Kunne ikke hente ansatte"
          errorAction={fetchData}
          errorActionText="Prøv på nytt"
        />
      )
    }

    const paginationPadding = isDesktop ? [2, 0, 0, 0] : [2, 2, 0, 2]
    const contentPadding = isDesktop ? [1, 3] : []

    return (
      <Column padding={contentPadding} spacing={2}>
        <Column padding={[1, 3]} spacing={2}>
          <Text>
            Når du fordeler tilsynskvitteringen til en kollega vil den havne i
            oversikten til den du fordeler til.
          </Text>
          <SearchInput
            onChange={onChangeSearchInput}
            placeholder="Søk etter kollega"
            value={searchInput}
          />
          <Column margin={[0, 0, 1, 0]}>
            <Row>
              <Column>
                <ToggleSwitch
                  onClick={onToggleAlleKolleger}
                  checked={isAlleKolleger}
                  labelAfter={true}
                >
                  Vis kolleger utenfor din avdeling
                </ToggleSwitch>
              </Column>
            </Row>
          </Column>
        </Column>

        <SelectList
          dataList={dataList}
          onClick={onSelectKollega}
          selected={[String(selectedAnsattBrukernavn)]}
        />

        {searchedAnsatte.length > PAGE_SIZE && (
          <Column padding={paginationPadding}>
            <Pagination
              page={{
                number: pageNumber,
                totalPages: totalPages,
              }}
              onPaginationClick={onChangePage}
            />
          </Column>
        )}
      </Column>
    )
  }

  const renderTitle = () => {
    if (loadingStatus?.error) return 'En feil oppstod'
    if (loadingStatus?.loading) return 'Laster ansatte'
    return `Opprett tilsynskvittering til kollega i ${kontor?.name?.[0]?.toLowerCase()}${kontor?.name?.slice(
      1
    )}`
  }

  return (
    <ModalWrapper
      title={renderTitle()}
      onCancel={onCancel}
      onOk={handleFordelMelding}
      isOpen={isOpen}
      paddingHorizontal={0}
      buttonOkText="Opprett tilsynskvittering på kollega"
      className="fordelTilKollegaModal"
      fullscreenMobile
    >
      {renderContent()}
    </ModalWrapper>
  )
}
