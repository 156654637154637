import { ReplaySubject, combineLatest } from 'rxjs'
import { pluck } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'

export const config$ = new ReplaySubject<Record<string, string>>(1)
export const oidcConfig$ = new ReplaySubject(1)

const getConfig$ = ajax.getJSON('/config.json')

const oidcCert$ = ajax({
  url: '/login-api/certs',
}).pipe(pluck('response'), pluck('keys'))

const oidcLinks$ = ajax({
  url: '/login-api/.well-known/openid-configuration',
}).pipe(pluck('response'))

combineLatest([oidcCert$, oidcLinks$, getConfig$]).subscribe(
  ([certs, links, config]) => {
    if (certs && links && config) {
      config$.next(config as any)
      oidcConfig$.next({ certs, links })
    }
  }
)
