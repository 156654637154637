export const DiagnosticActions = {
  DIAGNOSTIC_INITIALIZED: 'Diagnostic: Initialized',
  DIAGNOSTIC_LOADING: 'Diagnostic: Loading',
  DIAGNOSTIC_ERROR: 'Diagnostic: Error',
  DIAGNOSTIC_SEND: 'Diagnostic: Send data',
  DIAGNOSTIC_SEND_FAIL: 'Diagnostic: Send data fail',
  DIAGNOSTIC_SEND_SUCCESS: 'Diagnostic: Send data success',
}

export const init = () => ({
  type: DiagnosticActions.DIAGNOSTIC_LOADING,
})

export const sendData = (data: any) => ({
  type: DiagnosticActions.DIAGNOSTIC_SEND,
  data,
})

export const sendDataSuccess = () => ({
  type: DiagnosticActions.DIAGNOSTIC_SEND_SUCCESS,
})

export const sendDataFail = (error: string) => ({
  type: DiagnosticActions.DIAGNOSTIC_SEND_FAIL,
  error,
})
