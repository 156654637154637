import { RiskScoringMetaData } from "../model/type"

export const RiskScoringActions = {

    LOAD_RISKSCORINGS: "LOAD_RISKSCORINGS",
    LOADING_RISKSCORINGS: "LOADING_RISKSCORINGS",
    ADD_RISKSCORINGS: "ADD_RISKSCORINGS",

    ADD_PLANNED_TILSYN: "ADD_PLANNED_TILSYN",
    SET_PLANNED_TILSYN: "SET_PLANNED_TILSYN",
    CANCEL_PLANNED_TILSYN: "CANCEL_PLANNED_TILSYN",

    WS_CONNECTED: "WS_CONNECTED",
    WS_DISCONNECTED: "WS_DISCONNECTED",
}

export const loadingRiskScoringData = (
    
) => ({
    type: RiskScoringActions.LOADING_RISKSCORINGS,
    currentRiskScoringData: [],
    previousRiskScoringData: [],
    loading: true
})

export const loadRiskScoringData = (
    riskScoringMetaData: RiskScoringMetaData
    ) => ({
        type: RiskScoringActions.LOAD_RISKSCORINGS,
        currentRiskScoringData: riskScoringMetaData.riskScoringDataList,
        previousRiskScoringData: riskScoringMetaData.riskScoringDataList,
        loading: false
})