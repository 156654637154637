import * as R from 'ramda'
import { IHeader } from '@mattilsynet/mt-ui/dist/components/table'

export const sortByKey =
  (sortingKey: string, sortDescending = false) =>
  <T>(data: T): T =>
    R.pipe(
      R.sortBy(
        R.pipe(
          R.prop(sortingKey),
          R.defaultTo(''),
          R.when(Number, Number),
          R.when(R.is(String), R.toLower)
        )
      ),
      R.when(() => sortDescending, R.reverse)
    )(data)

export const sortByPath =
  (sortingPath: string[], sortDescending = false) =>
  <T>(data: T): T =>
    R.pipe(
      R.sortBy(
        R.pipe(
          R.path(sortingPath),
          R.defaultTo(''),
          R.when(Number, Number),
          R.when(R.is(String), R.toLower)
        )
      ),
      R.when(() => sortDescending, R.reverse)
    )(data)

export const getSortableTableHeader = (
  header,
  sortColumn,
  sortArrow,
  setSortColumn,
  setSortArrow
): IHeader[] =>
  header.map((item) => {
    const sortableTableHeaderItem: IHeader = {
      display: item.display,
    }
    if (item.sortable) {
      sortableTableHeaderItem.sortable = !!item.sortable
      sortableTableHeaderItem.arrow =
        sortColumn === item.sortKey ? sortArrow : 'NONE'
      sortableTableHeaderItem.onClickHeader = () => {
        setSortColumn(item.sortKey)
        setSortArrow(sortArrow === 'DOWN' ? 'UP' : 'DOWN')
      }
    }
    return sortableTableHeaderItem
  })
