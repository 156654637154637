import { IUIState } from './types'
export const initialState: IUIState = {
  showDeleteModal: false,
  showArchiveModal: true,
  offline: false,
  env: 'prod',
  isDesktop: false,
  locationModal: {
    id: undefined,
    location: undefined,
  },
  errorAlert: {
    isShown: false,
  },
}
