import React from 'react'
import {
  TextLink,
  GridItem,
  Row,
  Grid,
  Column,
  RowItem,
  ShadowBox,
} from '@mattilsynet/mt-ui'
import buildInfo from '../../../package.json'
import './style.css'

interface IAboutViewProps {
  goBack: () => void
}

export const AboutView = ({ goBack }: IAboutViewProps) => (
  <Grid id="about">
    <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
      <Row>
        <TextLink leftArrow onClick={goBack}>
          Tilbake
        </TextLink>
      </Row>
    </GridItem>
    <GridItem
      xl={[3, -3]}
      lg={[3, -3]}
      md={[1, -1]}
      sm={[1, -1]}
      backgroundColor="#fff"
    >
      <ShadowBox>
        <Column spacing={1} padding={2.5}>
          <h1>Om applikasjonen</h1>
          <Column spacing={1}>
            <h2>Datadrevet Risikobasering</h2>
            <Row>
              Datadrevet Risikobasering er et verktøy som skal bistå inspektørene med å gjøre utvalg
              av tilsynsobjekter. Løsningen tildeler en risikoverdi til hvert tilsynsobjekt basert på 
              forskjellige datakilder som USR-data og PM/AM-koder. Etter hvert vil flere datakilder 
              inkluderes i grunnlaget for risikoberegningen.
            </Row>
          </Column>
          <Column spacing={1}>
            <h2>Brukerstøtte</h2>
            <div>
              <span className="space">
                Hvis du har behov for hjelp kan du opprette
              </span>
              <TextLink href="https://makuba.mattilsynet.no/" linkOutside>
                brukerstøttesak
              </TextLink>
            </div>
            <Row>
              Du kan også kontakte IKT-brukerstøtte på telefon eller e-post:
            </Row>
            <Row>
              <RowItem className="space">Telefon:</RowItem>
              <TextLink href="tel:22 77 88 99">22 77 88 99</TextLink>
            </Row>
            <Row>
              <RowItem className="space">E-post:</RowItem>
              <TextLink href="mailto:99@mattilsynet.no">
                99@mattilsynet.no
              </TextLink>
            </Row>
          </Column>
          <Column spacing={1}>
            <h2>Version</h2>
            <Row>{buildInfo.version}</Row>
          </Column>
        </Column>
      </ShadowBox>
    </GridItem>
  </Grid>
)
