import {
  Tilsynsobjekt,
  RiskScoringMetaData,
  IVirksomhetAdresse,
  PageMetadata,
} from "../../model/type";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  LoadingDots,
  LoadingSpinner,
  Row,
  ShadowBox,
  ThemeContext,
} from "@mattilsynet/mt-ui";
// import { useNavigate } from 'react-router-dom';

import { loadRiskScoringData } from "../../stores/riskScoringStore/actionCreators";
import React, { useContext, useEffect, useState } from "react";
import { Dispatch } from "redux";
import { loadingRiskScoringData } from "../../actions/riskscoringActions";
import { IStoreState } from "../../reducers/types";
import { StyledRiskScoringTable } from "../styledriskscoringtable/StyledRiskScoringTable";
import { getSortableTableHeader } from "../../common/sorting";
import { TilsynsobjektRow } from "../riskscored-tilsynsobjekt-row";
import {
  closeRowAction,
  openRowAction,
} from "../../actions/tilsynsobjektRowActions";
import {
  fetchVirksomhetsAddress,
  loadAddressFail,
  loadAddressOk,
} from "../../actions/addressActions";
import { fetchAddress } from "../../api/address";
import { FordelTilsynTilModal } from "../../modals/fordel-til-kollega-modal";
import {
  planTilsynAction,
  planTilsynFail,
  planTilsynOk,
} from "../../actions/tilsynActions";
import {
  getSisteTilsyn,
  getTidligereTilsyn,
  planTilsyn,
} from "../../api/tilsyn";
import { toast } from "@mattilsynet/mt-common";
import {
  tilsynPlannedError,
  noRiskScoringWithinArea,
  tilsynPlannedSuccess,
} from "../../common/toast";
import {
  loadSisteTilsyn,
  loadSisteTilsynFail,
  loadSisteTilsynOk,
} from "../../actions/sisteTilsynActions";
import { Filtrering } from "../filtrering";
import {
  filterRiskScoringByNotPlannedOrOngoingTilsyn,
  filterRiskScoringBySisteTilsyn,
} from "../../utils/utils";
import { useLoadOnScrollBottom } from "../../common/hooks";

const RiskScoringTable = ({ fetchData }: { fetchData }) => {
  const [filterSisteTilsynDate, setFilterSisteTilsynDate] =
    useState<string>(undefined);
  const [
    filterPlannedOrOngoingTilsynskvittering,
    setFilterPlannedOrOngoingTilsynskvittering,
  ] = useState<boolean>(false);

  const dispatch: Dispatch<any> = useDispatch();
  const theme = useContext(ThemeContext);

  const tilsynsobjekter: readonly Tilsynsobjekt[] = useSelector(
    (state: IStoreState) => state.svinehold.tilsynsobjekter ?? [],
    shallowEqual
  );

  const { loading, initialized } = useSelector(
    (state: IStoreState) => state.svinehold,
    shallowEqual
  );

  const { accessToken } = useSelector((state: IStoreState) => state.user);

  const currentTotalShown = React.useCallback(() => {
    return tilsynsobjekter.filter((t) =>
      filterRiskScoringBySisteTilsyn(t, sisteTilsyn, filterSisteTilsynDate)
    ).length;
  }, [tilsynsobjekter, filterSisteTilsynDate]);

  const loadData = React.useCallback(
    (riskScoringMetaData: RiskScoringMetaData, page: PageMetadata) => {
      dispatch(
        loadRiskScoringData(
          riskScoringMetaData ? riskScoringMetaData.riskScoringDataList : [],
          page
        )
      );

      if (page.totalElements < 1) {
        dispatch(toast.actions.showToast(noRiskScoringWithinArea()));
      }

      const tilsynsobjektIds = riskScoringMetaData.riskScoringDataList.map(
        (t) => t.tilsynsobjektId
      );

      dispatch(loadSisteTilsyn(tilsynsobjektIds));

      getSisteTilsyn(
        accessToken,
        tilsynsobjektIds,
        (data: any) => {
          dispatch(loadSisteTilsynOk(data));
        },
        () => {
          dispatch(loadSisteTilsynFail(tilsynsobjektIds));
        }
      );
    },
    []
  );

  const { pageMetaData } = useSelector((state: IStoreState) => state.svinehold);

  const { openRows } = useSelector((state: IStoreState) => state.openRows);

  const { addressOk } = useSelector((state: IStoreState) => state.address);

  const { sisteTilsyn } = useSelector(
    (state: IStoreState) => state.sisteTilsyn
  );

  const { tidligereTilsyn } = useSelector(
    (state: IStoreState) => state.tidligereTilsynskvitteringer
  );

  const loadAddressOnOpen = (tilsynsobjektId: string): boolean => {
    return !addressOk.some((t) => t == tilsynsobjektId);
  };

  const toggleOpenRow = (tilsynsobjektId) => {
    if (openRows.some((id) => id === tilsynsobjektId)) {
      closeRow(tilsynsobjektId);
    } else {
      openRow(tilsynsobjektId);
    }
  };

  const getOrgNummer = (tilsynsobjektId: string) => {
    return tilsynsobjekter.filter(
      (t) => t.tilsynsobjektId == tilsynsobjektId
    )[0].orgnummer;
  };

  const openRow = (tilsynsobjektId: string) => {
    if (
      tilsynsobjekter.filter(
        (t) =>
          t.tilsynsobjektId == tilsynsobjektId &&
          loadAddressOnOpen(tilsynsobjektId)
      ).length > 0
    ) {
      dispatch(fetchVirksomhetsAddress(tilsynsobjektId));

      fetchAddress(
        accessToken,
        getOrgNummer(tilsynsobjektId),
        (address: IVirksomhetAdresse) => {
          dispatch(loadAddressOk(tilsynsobjektId, address));
        },
        () => {
          dispatch(loadAddressFail(tilsynsobjektId));
        }
      );
    }

    dispatch(openRowAction(tilsynsobjektId));
  };

  const closeRow = (tilsynsobjektId: string) => {
    dispatch(closeRowAction(tilsynsobjektId));
  };

  const fetchNextData = () => {
    dispatch(loadingRiskScoringData());
    fetchData(accessToken, pageMetaData, loadData);
  };

  useEffect(() => {
    fetchNextData();
  }, []);

  useLoadOnScrollBottom(fetchNextData, loading);

  const [sortColumn, setSortColumn] = useState("sistRedigertDayjs");
  const [sortArrow, setSortArrow] = useState<"DOWN" | "UP">("DOWN");

  const tableHeader = getSortableTableHeader(
    [
      { display: "Risiko" },
      { display: "Siste tilsyn" },
      { display: "Virksomhetsnavn" },
      { display: "MT kontor" },
      { display: " " },
    ],
    sortColumn,
    sortArrow,
    setSortColumn,
    setSortArrow
  );

  const [isFordelTilsynModelOpen, setIsFordelTilsynModalOpen] = useState(false);

  const [selectedTilsynsobjekt, setSelectedTilsynsobjekt] = useState(null);

  const onOpenFordelTilKollegaModal = (tilsynsobjektId: string) => {
    setIsFordelTilsynModalOpen(true);
    setSelectedTilsynsobjekt(tilsynsobjektId);
  };

  const fordelTilsyn = (username: string, tilsynsobjektId: string) => {
    dispatch(planTilsynAction(tilsynsobjektId));
    planTilsyn(
      tilsynsobjektId,
      username,
      accessToken,
      () => {
        dispatch(planTilsynOk(tilsynsobjektId));
        dispatch(toast.actions.showToast(tilsynPlannedSuccess()));
        setIsFordelTilsynModalOpen(false);
      },
      () => {
        dispatch(planTilsynFail(tilsynsobjektId));
        dispatch(toast.actions.showToast(tilsynPlannedError()));
      }
    );
  };

  const renderContent = () => {
    return loading && !initialized ? (
      <LoadingSpinner title="Laster inn riskscoring" />
    ) : (
      <>
        <StyledRiskScoringTable header={tableHeader} theme={theme}>
          {tilsynsobjekter
            .filter((t) =>
              filterRiskScoringBySisteTilsyn(
                t,
                sisteTilsyn,
                filterSisteTilsynDate
              )
            )
            .filter((t) =>
              filterRiskScoringByNotPlannedOrOngoingTilsyn(
                t,
                tidligereTilsyn,
                filterPlannedOrOngoingTilsynskvittering
              )
            )
            .map((tilsynsobjekt: Tilsynsobjekt, index: number) => (
              <TilsynsobjektRow
                key={tilsynsobjekt.tilsynsobjektId}
                index={index}
                tilsynsobjekt={tilsynsobjekt}
                openRows={openRows}
                toggleOpenRow={toggleOpenRow}
                tableHeader={tableHeader}
                onFordelTilKollega={onOpenFordelTilKollegaModal}
              />
            ))}
        </StyledRiskScoringTable>

        {loading && (
          <Row justify="center" padding={1}>
            <LoadingDots />
          </Row>
        )}

        {isFordelTilsynModelOpen && (
          <FordelTilsynTilModal
            isOpen={isFordelTilsynModelOpen}
            onCancel={() => setIsFordelTilsynModalOpen(false)}
            tilsynsobjektId={selectedTilsynsobjekt}
            onSelect={fordelTilsyn}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Filtrering
        pageMetaData={pageMetaData}
        filterSisteTilsynDate={filterSisteTilsynDate}
        setFilterSistetilsynDate={setFilterSisteTilsynDate}
        setFilterPlannedOrOngoingTilsynskvittering={
          setFilterPlannedOrOngoingTilsynskvittering
        }
        currentTotalShown={currentTotalShown}
      />

      <ShadowBox>{renderContent()}</ShadowBox>
    </>
  );
};

export default RiskScoringTable;
