import { useActionOnBackButton } from '../../common/hooks'
import { Modal } from '@mattilsynet/mt-ui'
import React, { ComponentProps } from 'react'

interface IModalWrapperProps extends ComponentProps<typeof Modal> {
  childModalOpen?: boolean
}
export const ModalWrapper = ({
  onCancel,
  isOpen,
  focusGroupElementIds,
  childModalOpen,
  children,
  ...rest
}: IModalWrapperProps) => {
  useActionOnBackButton(onCancel, !!isOpen && !childModalOpen)
  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      focusGroupElementIds={focusGroupElementIds ?? ['common-toastarea']}
      {...rest}
    >
      {children}
    </Modal>
  )
}
